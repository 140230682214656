import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { firebase } from "../../firebase/config";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaSearch } from "react-icons/fa";
import FullScreenMobile from "./fullScreenMobile";

import "../index.css";

const MobileTopMenu = ({ user, subscribed }) => {
  const [navVisible, setNavVisible] = useState(false);
  const [uid, setUID] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const myRef = React.createRef();

  const hideNav = () => {
    document.body.style.overflow = "unset";
    setNavVisible(false);
  };

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      setUID(uid);
    }
    executeScroll();
  }, []);
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <>
      {navVisible && <FullScreenMobile user={user} hideNav={hideNav} />}

      <div ref={myRef} style={{ position: "absolute", top: 0 }}></div>
      <div style={{ zIndex: 100000 }}>
        <div
          style={{
            fontSize: "19px",
            fontFamily: "GellixBold",
            zIndex: 2020,
            left: 120,
            top: 0,
            width: "100vw",
            backgroundColor: "#101340",
            borderRadius: "0px",
            paddingTop: 0,
            height: 0,
            paddingBottom: 0,
            boxShadow: "0 2px 4px 0 rgb(0 0 0 / 5%)",
          }}
          className="d-flex justify-content-center hoverBorder"
        ></div>

        <div
          style={{
            fontSize: "19px",
            fontFamily: "GellixBold",
            zIndex: 2020,
            left: 0,
            top: 0,
            backgroundColor: "#101340",
            color: "black",
            width: "100vw",
            paddingTop: 15,
            paddingBottom: 0,
            height: 46,
            boxShadow: "0 2px 4px 0 rgb(0 0 0 / 5%)",
            position: "fixed",
          }}
        >
          <div>
            {/* <IoPersonSharp style={{ display: "inline-block", color: "black", fontWeight: "bold", fontFamily: "Righteous", marginRight: 10, fontSize: 30, marginTop: 6 }} /> */}
            <GiHamburgerMenu
              onClick={() => {
                setNavVisible(true);
                document.body.style.overflow = "hidden";
              }}
              style={{
                color: "white",
                fontWeight: "bold",
                position: "absolute",
                left: "8%",
                fontFamily: "Righteous",
                fontSize: 20,
                top: 14,
                zIndex: 4000,
              }}
            />
          </div>

          <NavLink to="/" exact onClick={() => window.scrollTo(0, 0)}>
            <MDBRow>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  className="d-inline"
                  style={{
                    color: "white",
                    fontWeight: "900",
                    marginTop: 0,
                    fontFamily: "TahomaBold",
                    fontSize: 20,
                    opacity: 1,
                    lineHeight: 1.2,
                  }}
                >
                  BollywoodAI
                </p>
              </MDBCol>
            </MDBRow>
          </NavLink>
        </div>
      </div>

      {menuOpen ? (
        <div
          style={{
            position: "absolute",
            height: "105vh",
            width: "100vw",
            zIndex: 120000,
            top: 0,
            backgroundColor: "#fcfcfc",
          }}
        >
          <RiCloseLine
            onClick={() => {
              document.body.style.overflow = "scroll";
              setMenuOpen(false);
            }}
            style={{
              position: "absolute",
              right: 20,
              top: 20,
              opacity: 0.8,
              fontSize: 26,
              color: "black",
            }}
          />

          <MDBRow style={{ marginTop: 100 }}>
            <MDBCol size="12">
              <NavLink
                to="/about"
                exact
                onClick={() => {
                  document.body.style.overflow = "scroll";
                  window.scrollTo(0, 0);
                }}
              >
                <p
                  className="d-inline"
                  style={{
                    color: "black",
                    marginLeft: 50,
                    fontWeight: "500",
                    top: 17,
                    fontFamily: "Sofia Pro",
                    fontSize: 35,
                    opacity: 0.8,
                    lineHeight: 1.2,
                  }}
                >
                  About us
                </p>
              </NavLink>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginTop: 20 }}>
            <MDBCol size="12">
              <NavLink
                to="/faq"
                exact
                onClick={() => {
                  document.body.style.overflow = "scroll";
                  window.scrollTo(0, 0);
                }}
              >
                <p
                  className="d-inline"
                  style={{
                    color: "black",
                    marginLeft: 50,
                    fontWeight: "500",
                    top: 17,
                    fontFamily: "Sofia Pro",
                    fontSize: 35,
                    opacity: 0.8,
                    lineHeight: 1.2,
                  }}
                >
                  FAQ
                </p>
              </NavLink>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginTop: 20 }}>
            <MDBCol size="12">
              <NavLink
                to="/become-a-seller"
                exact
                onClick={() => {
                  document.body.style.overflow = "scroll";
                  window.scrollTo(0, 0);
                }}
              >
                <p
                  className="d-inline"
                  style={{
                    color: "black",
                    marginLeft: 50,
                    fontWeight: "500",
                    top: 17,
                    fontFamily: "Sofia Pro",
                    fontSize: 35,
                    opacity: 0.8,
                    lineHeight: 1.2,
                  }}
                >
                  Become a seller
                </p>
              </NavLink>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginTop: 20 }}>
            <MDBCol size="12">
              <NavLink
                to="/contact"
                exact
                onClick={() => {
                  document.body.style.overflow = "scroll";
                  window.scrollTo(0, 0);
                }}
              >
                <p
                  className="d-inline"
                  style={{
                    color: "black",
                    marginLeft: 50,
                    fontWeight: "500",
                    top: 17,
                    fontFamily: "Sofia Pro",
                    fontSize: 35,
                    opacity: 0.8,
                    lineHeight: 1.2,
                  }}
                >
                  Contact us
                </p>
              </NavLink>
            </MDBCol>
          </MDBRow>
        </div>
      ) : null}
    </>
  );
};

export default MobileTopMenu;
