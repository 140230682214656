import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { firebase } from "../../firebase/config";
import enhanceWithClickOutside from "react-click-outside";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";

const Popup = ({ email, setPopUpVisible, popUpVisible, uid }) => {
  useEffect(() => {
    if (uid) {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          // if (doc.data().subscribed === true) {
          //   setSubscriptionActive(true);
          // } else {
          //   setSubscriptionActive(false);
          // }
        });
    }
  }, []);

  const handleClickOutside = () => {
    setPopUpVisible(false);
  };

  const toggle = () => {
    setPopUpVisible(!popUpVisible);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setPopUpVisible(false);
      }}
    >
      <div
        className={`fade-in-out ${popUpVisible ? "visible" : ""}`}
        style={{
          paddingBottom: 6,
          width: 200,
          backgroundColor: "white",
          position: "fixed",
          right: 50,
          top: 60,
          padding: "5px 0px",
          borderRadius: 2,
          boxShadow: "0px 0px 47px -6px rgba(0,0,0,1)",
          overflow: "hidden",
        }}
      >
        <MDBRow style={{ color: "black", paddingLeft: 14 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 13,
                marginTop: 5,
                opacity: 0.9,
              }}
            >
              {email}
            </p>
          </MDBCol>
          <MDBCol
            style={{
              borderTop: "1px solid #E8E8E8",
              width: 100,
              marginTop: -8,
            }}
            size="12"
          >
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 13,
                marginTop: 6,
                opacity: 0.9,
              }}
            >
              Personal
            </p>
          </MDBCol>
          <MDBCol
            style={{
              borderTop: "1px solid #E8E8E8	",
              marginTop: -8,
            }}
            size="12"
          >
            <NavLink to="/settings" exact>
              <p
                style={{
                  fontFamily: "PlusJSSemiBold",
                  fontSize: 13,
                  marginTop: 6,
                  opacity: 0.9,
                  color: "black",
                }}
                onClick={() => setPopUpVisible(false)}
              >
                Manage account
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ marginTop: -8 }} size="12">
            <NavLink to="/explore" exact>
              <p
                style={{
                  fontFamily: "PlusJSSemiBold",
                  fontSize: 13,
                  marginTop: 0,
                  opacity: 0.9,
                  color: "black",
                }}
                onClick={() => setPopUpVisible(false)}
              >
                Explore
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ marginTop: -8 }} size="12">
            <NavLink to="/contact" exact>
              <p
                style={{
                  fontFamily: "PlusJSSemiBold",
                  fontSize: 13,
                  marginTop: 0,
                  opacity: 0.9,
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => setPopUpVisible(false)}
              >
                Contact
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol
            style={{
              borderTop: "1px solid #E8E8E8	",
              marginTop: -8,
            }}
            size="12"
          >
            <NavLink to="/privacy" exact>
              <p
                style={{
                  fontFamily: "PlusJSSemiBold",
                  fontSize: 13,
                  marginTop: 6,
                  opacity: 0.9,
                  color: "black",
                }}
                onClick={() => {
                  setPopUpVisible(false);
                }}
              >
                Privacy Policy
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ marginTop: -8 }} size="12">
            <NavLink to="/terms-of-use" exact>
              <p
                style={{
                  fontFamily: "PlusJSSemiBold",
                  fontSize: 13,
                  marginTop: 0,
                  opacity: 0.9,
                  color: "black",
                }}
                onClick={() => setPopUpVisible(false)}
              >
                Terms & Policies
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ marginTop: -8 }} size="12">
            <p
              style={{
                fontFamily: "PlusJSSemiBold",
                fontSize: 13,
                marginTop: 0,
                opacity: 0.9,
                cursor: "pointer",
              }}
              onClick={() => {
                mixpanel.track("Logged out");
                setPopUpVisible(false);
                firebase.auth().signOut();
              }}
            >
              Log out
            </p>
          </MDBCol>
        </MDBRow>
      </div>
    </OutsideClickHandler>
  );
};

export default withRouter(enhanceWithClickOutside(Popup));
