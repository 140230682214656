import React, { useEffect, useState, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { IoCall } from "react-icons/io5";
import { withRouter } from "react-router-dom";
import { firebase } from "../../firebase/config";
import { FaLock } from "react-icons/fa";
import CallMobile from "../Layout/CallMobile";
import { Howl } from "howler";
import mixpanel from "mixpanel-browser";

const RowMobile = ({ avatars, color, Category, user, subscribed }) => {
  const endCallRef = useRef();
  const backgroundRef = useRef();
  const [image, setImage] = useState(null);
  const [callingVisible, setCallingVisible] = useState(false);
  const [calling, setCalling] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [prompt, setPrompt] = useState("");
  const [timer, setTimer] = useState(null);
  const [micAccessGranted, setMicAccessGranted] = useState(false);
  const [callingSound, setCallingSound] = useState(null);

  function getMicrophoneAccess() {
    return navigator.mediaDevices.getUserMedia({ audio: true });
  }

  useEffect(() => {
    if (calling) {
      window.scrollTo(0, 0);
      const sound = new Howl({
        src: [
          "https://upcdn.io/FW25bFR/raw/Apple%20iphone%20%206%20Original%20Ringtone%20Sound%20Effect.mp3",
        ],
        loop: true,
        volume: 1,
      });

      sound.play();

      setCallingSound(sound);
    }
  }, [calling]);

  useEffect(() => {
    if (!calling && callingSound) {
      if (callingSound.playing()) {
        callingSound.stop();
      }
    }
  }, [calling, callingSound]);

  useEffect(() => {
    if (user) {
      let uid = user.uid;

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          if (doc.data().timer) {
            setTimer(doc.data().timer);
          }
        });
    }
  }, [user]);

  useEffect(() => {
    if (timer && calling && !subscribed) {
      if (timer > 80) {
        setCalling(false);
        window.location.href = "/upgrade";
      }
    }
  }, [timer, calling, subscribed]);

  const playEndSound = () => {
    const howl = new Howl({
      src: [
        "https://res.cloudinary.com/dvgkc6gso/video/upload/v1679690772/Y2Mate.is_-_FaceTime_End_Call_Sound_Tiktok_Orignal_-ZOK0F8LhYxg-128k-1655341892542_lubme7.mp3",
      ],
      format: ["mp3"],
      onload: () => {
        howl.play();
      },
      onloaderror: () => {
        console.error("Error loading audio");
      },
    });
  };

  useEffect(() => {
    if (calling) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.zoom = "1"; // Set the zoom level to 100%
    } else {
      document.body.style.overflow = "scroll";
    }
    // You can also set these styles on the cont
  }, [calling]);
  return (
    <>
      {callingVisible && calling && user && micAccessGranted && (
        <CallMobile
          image={image}
          isMobile={false}
          visible={callingVisible}
          calling={calling}
          setCalling={setCalling}
          sellerName={sellerName}
          voiceID={voiceID}
          prompt={prompt}
          setCallingSound={setCallingSound}
          uid={user.uid}
          callingSound={callingSound}
          playEndSound={playEndSound}
        />
      )}
      <MDBRow
        style={{
          marginTop: 0,
          overflowY: "hidden",
          backgroundColor: "#2b2b2b",
          paddingTop: 24,
          paddingBottom: 20,
          overflowX: "hidden",
        }}
        className="backy60"
      >
        <MDBRow
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 0,
            width: 400,
            alignSelf: "center",
            margin: "20px auto",
          }}
        >
          {avatars.map((seller) => {
            return (
              <MDBCol key={seller.name} size="4" style={{ padding: 0 }}>
                <MDBRow>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <img
                      src={seller.image}
                      style={{
                        width: 110,
                        height: 110,
                        objectFit: "cover",
                        borderRadius: 200,
                        border:
                          seller.Free === true || subscribed
                            ? "4px solid #77FFBA"
                            : "4px solid grey",
                        opacity: seller.Free === true || subscribed ? 1 : 0.5,
                      }}
                      onClick={() => {
                        if (!firebase.auth().currentUser) {
                          window.location.href = "/sign-in";
                        } else {
                          if (
                            seller.Free !== true ||
                            (!subscribed && timer > 80)
                          ) {
                            window.location.href = "/upgrade";
                          } else {
                            if (!micAccessGranted) {
                              window.scrollTo(0, 0);

                              getMicrophoneAccess()
                                .then(() => {
                                  mixpanel.track("Called", {
                                    name: seller.name,
                                  });

                                  setCallingVisible(true);
                                  setCalling(true);
                                  setSellerName(seller.name);
                                  setVoiceID(seller.voiceID);
                                  setImage(seller.image);
                                  setPrompt(seller.prompt);
                                  window.scrollTo({
                                    top: 0,
                                  });
                                  setMicAccessGranted(true);
                                })
                                .catch((error) => {
                                  window.location.reload();
                                });
                            } else {
                              window.scrollTo(0, 0);

                              mixpanel.track("Called", {
                                name: seller.name,
                              });

                              setCallingVisible(true);
                              setCalling(true);
                              setSellerName(seller.name);
                              setVoiceID(seller.voiceID);
                              setImage(seller.image);
                              setPrompt(seller.prompt);
                              window.scrollTo({
                                top: 0,
                              });
                              setMicAccessGranted(true);
                            }
                          }
                        }
                      }}
                    />
                    <div
                      style={{
                        fontSize: 19,
                        marginTop: 0,
                        fontFamily: "PlusJSBold",
                        position: "absolute",
                        top: 90,
                        textAlign: "center",
                        alignSelf: "center",
                        height: 30,
                        width: 30,
                        backgroundColor:
                          seller.Free === true || subscribed
                            ? "#4cda63"
                            : "grey",

                        borderRadius: 200,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!firebase.auth().currentUser) {
                          window.location.href = "/sign-in";
                        } else {
                          if (
                            seller.Free !== true ||
                            (!subscribed && timer > 80)
                          ) {
                            window.location.href = "/upgrade";
                          } else {
                            if (!micAccessGranted) {
                              window.scrollTo(0, 0);

                              getMicrophoneAccess()
                                .then(() => {
                                  mixpanel.track("Called", {
                                    name: seller.name,
                                  });

                                  setCallingVisible(true);
                                  setCalling(true);
                                  setSellerName(seller.name);
                                  setVoiceID(seller.voiceID);
                                  setImage(seller.image);
                                  setPrompt(seller.prompt);
                                  window.scrollTo({
                                    top: 0,
                                  });
                                  setMicAccessGranted(true);
                                })
                                .catch((error) => {
                                  window.location.reload();
                                });
                            } else {
                              window.scrollTo(0, 0);

                              mixpanel.track("Called", {
                                name: seller.name,
                              });

                              setCallingVisible(true);
                              setCalling(true);
                              setSellerName(seller.name);
                              setVoiceID(seller.voiceID);
                              setImage(seller.image);
                              setPrompt(seller.prompt);
                              window.scrollTo({
                                top: 0,
                              });
                              setMicAccessGranted(true);
                            }
                          }
                        }
                      }}
                    >
                      {seller.Free === true || subscribed ? (
                        <IoCall
                          style={{
                            marginTop: 0,
                            fontSize: 16,
                          }}
                        />
                      ) : (
                        <FaLock
                          style={{
                            marginTop: 0,
                            fontSize: 14,
                          }}
                        />
                      )}
                    </div>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontSize: 15,
                        marginTop: 20,
                        fontFamily: "PlusJSBold",
                        textAlign: "center",
                      }}
                    >
                      AI{" "}
                      {seller.name[0].toUpperCase() + seller.name.substring(1)}
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontSize: 15,
                        marginTop: -13,
                        fontFamily: "PlusJSBold",
                        textAlign: "center",
                        opacity: 0.7,
                      }}
                    >
                      {seller.Subtitle}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBRow>
      <div style={{ color: "white" }}>
        {/* <audio id="Jibril" volume={1} ref={audioRef} /> */}
        <audio
          volume={1}
          id="Jibril"
          loop
          ref={backgroundRef}
          src="https://res.cloudinary.com/dvgkc6gso/video/upload/v1676327827/backgroundSound_gyx0b4.mp3"
        />
      </div>
    </>
  );
};

export default withRouter(RowMobile);
