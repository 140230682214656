async function concatenateAudioFiles(urls) {
  // Prepare the Transloadit assembly JSON data
  const data = {
    // template_id: '0dba01b1b64c4ab2826531fbd6095e76',
    steps: {
      video: {
        robot: "/http/import",
        url: "https://firebasestorage.googleapis.com/v0/b/rogueai.appspot.com/o/WoT%20Blitz%202023-04-07%2013-08-59.mp4?alt=media&token=89f5fa28-fe31-4c4f-8ead-22f6f6962895",
      },
      concat: {
        use: {
          steps: urls.map((_url, i) => ({
            name: `import${i}`,
            as: `audio_${i + 1}`,
          })),
        },
        robot: "/audio/concat",
        ffmpeg_stack: "v4.3.1",
        result: true,
        bundle_steps: true,
      },
      normalized: {
        use: "concat",
        robot: "/audio/encode",
        result: true,
        ffmpeg: {
          af: "loudnorm=I=-16:TP=-1.5:LRA=11",
        },
        ffmpeg_stack: "v4.3.1",
        preset: "mp3",
      },
      remove_silence: {
        use: "normalized",
        robot: "/audio/encode",
        result: true,
        ffmpeg: {
          af: "silenceremove=stop_periods=-1:stop_duration=1:stop_threshold=-90dB",
        },
        ffmpeg_stack: "v4.3.1",
        preset: "mp3",
      },
    },
    auth: {
      key: "d9866500bbc24ce4ab930288d8c3a718",
    },
  };

  // Add the audio files to the assembly
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];

    data.steps[`import${i}`] = {
      robot: "/http/import",
      url,
    };
  }

  const formData = new FormData();
  formData.append("params", JSON.stringify(data));

  // Submit the assembly to Transloadit
  // const response = await fetch('https://api2.transloadit.com/assemblies', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //         params: data,
  //     }),
  //     headers: { 'Content-Type': 'application/json' },
  // });
  const response = await fetch("https://api2.transloadit.com/assemblies", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`Error creating assembly: ${await response.text()}`);
  }

  // Wait for the assembly to finish
  const { assembly_id } = await response.json();
  const statusUrl = `https://api2.transloadit.com/assemblies/${assembly_id}`;
  let statusResponse;
  let statusJSON;

  do {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    statusResponse = await fetch(statusUrl);
    if (!statusResponse.ok) {
      throw new Error(
        `Error checking assembly status: ${await statusResponse.text()}`
      );
    }
    statusJSON = await statusResponse.json();
  } while (statusJSON.ok === "ASSEMBLY_EXECUTING");

  // Get the URL of the concatenated audio file from the assembly result

  const removeSilenceResultUrl = statusJSON.results.remove_silence[0].ssl_url;

  return removeSilenceResultUrl;
}

export default concatenateAudioFiles;
