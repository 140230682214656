import React, { Component, useEffect, useState } from "react";
import "../index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink } from "react-router-dom";
import SuggestAvatar from "./SuggestAvatar";
import { firebase } from "../../firebase/config";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");

  const submitName = (name) => {
    firebase.firestore().collection("suggestions").add({
      name: name,
    });
    setIsOpen(false);
  };

  return (
    <>
      <SuggestAvatar
        submitName={submitName}
        isOpen={isOpen}
        setName={setName}
        name={name}
        setIsOpen={setIsOpen}
      />
      <div
        style={{
          backgroundColor: "#101340",
          borderTop: "0px solid #E0E0E0",
          color: "white",
          paddingBottom: "5vh",
          width: "100%",
        }}
      >
        <MDBRow>
          <div style={{ marginLeft: 100, marginTop: 100 }} id="discord-button">
            <a href="https://discord.com/invite/ypJkaY3ZGr" target="_blank">
              <div class="icon">
                <svg
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 245 240"
                >
                  <path
                    class="st0"
                    d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                  />
                  <path
                    class="st0"
                    d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                  />
                </svg>
              </div>
              <span>Join the Discord</span>
            </a>
          </div>
        </MDBRow>

        <MDBRow style={{ width: "100%" }}>
          <MDBCol size="3">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 90,
                marginLeft: 80,
              }}
            >
              <p
                style={{
                  color: "#BEA2DA",
                  fontWeight: "900",
                  fontFamily: "TahomaBold",
                  fontSize: 33,
                  opacity: 1,
                  lineHeight: 1.2,
                  marginLeft: 0,
                }}
              >
                BollywoodAI
              </p>
            </div>
          </MDBCol>
          <MDBCol size="3">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontWeight: "600",
                marginTop: 90,
                fontSize: 18,
                marginLeft: 100,
              }}
            >
              Support
            </p>

            <NavLink to="/about" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: 21,
                  fontSize: 16,
                  marginLeft: 100,
                  color: "white",
                  opacity: 0.8,
                }}
              >
                About
              </p>
            </NavLink>
            <NavLink to="/faq" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: -3,
                  fontSize: 16,
                  marginLeft: 100,
                  color: "white",
                  opacity: 0.8,
                }}
              >
                FAQ
              </p>
            </NavLink>
            <NavLink to="/contact" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: -3,
                  fontSize: 16,
                  marginLeft: 100,
                  color: "white",
                  opacity: 0.8,
                }}
              >
                Contact us
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol size="3">
            <p
              style={{
                fontFamily: "PlusJSBold",

                fontWeight: "600",
                marginTop: 90,
                fontSize: 18,
                marginLeft: 100,
              }}
            >
              Legal
            </p>
            <NavLink to="/privacy" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: 21,
                  fontSize: 16,
                  marginLeft: 100,
                  color: "white",
                  opacity: 0.8,
                }}
              >
                Privacy Policy
              </p>
            </NavLink>
            <NavLink
              to="/terms-of-use"
              exact
              onClick={() => window.scrollTo(0, 0)}
            >
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: -3,
                  fontSize: 16,
                  marginLeft: 100,
                  color: "white",
                  opacity: 0.8,
                }}
              >
                Terms of Service
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol size="3"></MDBCol>
          <MDBCol style={{ marginTop: 40 }} size="3">
            <p
              style={{
                display: "inline-block",
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: 100,
                opacity: 0.8,
              }}
            >
              © SHOOK LLC 2023
            </p>
          </MDBCol>
          <MDBCol style={{ marginTop: 40, marginLeft: 100 }} size="1">
            <NavLink
              to="/about"
              style={{ color: "white", opacity: 0.8 }}
              exact
              onClick={() => window.scrollTo(0, 0)}
            >
              <p
                style={{
                  display: "inline-block",
                  fontFamily: "PlusJSMedium",

                  fontSize: 14,
                  marginLeft: -40,
                  opacity: 0.8,
                }}
              >
                About
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ marginTop: 40 }} size="1">
            <NavLink
              to="/contact"
              style={{ color: "white", opacity: 0.8 }}
              exact
              onClick={() => window.scrollTo(0, 0)}
            >
              <p
                style={{
                  display: "inline-block",
                  fontFamily: "PlusJSMedium",

                  fontSize: 14,
                  marginLeft: -60,
                  opacity: 0.8,
                }}
              >
                Contact
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ marginTop: 40 }} size="1">
            <NavLink
              to="/privacy"
              style={{ color: "white", opacity: 0.8 }}
              exact
              onClick={() => window.scrollTo(0, 0)}
            >
              <p
                style={{
                  display: "inline-block",
                  fontFamily: "PlusJSMedium",

                  fontSize: 14,
                  marginLeft: -70,
                  opacity: 0.8,
                }}
              >
                Privacy
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ marginTop: 40 }} size="1">
            <NavLink
              to="/terms-of-use"
              style={{ color: "white", opacity: 0.8 }}
              exact
              onClick={() => window.scrollTo(0, 0)}
            >
              <p
                style={{
                  display: "inline-block",
                  fontFamily: "PlusJSMedium",

                  fontSize: 14,
                  marginLeft: -80,
                  opacity: 0.8,
                }}
              >
                Terms
              </p>
            </NavLink>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default Footer;
