import React, { Component } from "react";
import { Redirect, Switch, Route, Router } from "react-router-dom";
import { firebase } from "./firebase/config";
import WelcomeMobile from "./Components/Welcome/WelcomeMobile";
import Landing from "./Components/Landing/Landing";
import LandingMobile from "./Components/Landing/LandingMobile";
import About from "./Components/About/About";
import AboutMobile from "./Components/About/AboutMobile";
import SignInMobile from "./Components/SignIn/SignInMobile";
import FAQ from "./Components/FAQ/FAQ";
import FAQMobile from "./Components/FAQ/FAQMobile";
import Contact from "./Components/Contact/Contact";
import ContactMobile from "./Components/Contact/ContactMobile";
import Privacy from "./Components/Privacy/Privacy";
import PrivacyMobile from "./Components/Privacy/PrivacyMobile";
import Terms from "./Components/Terms/Terms";
import TermsMobile from "./Components/Terms/TermsMobile";
import Clip from "./Components/Clip/Clip";
import ClipMobile from "./Components/Clip/ClipMobile";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import UpgradeMobile from "./Components/Upgrade/UpgradeMobile";
import { useUser } from "./UserContext";
import mixpanel from "mixpanel-browser";
import Cloning from "./Components/Cloning/Cloning";
import CloningMobile from "./Components/Cloning/CloningMobile";
//nope
mixpanel.init("fe29309223f16f6de3df24a2ae50eceb", { debug: true });

const App = ({ history }) => {
  const [isDesktop, setIsDesktop] = useState(0);
  const [version, setVersion] = useState("");
  const { user, setUser } = useUser();
  const [recordingText, setRecordingText] = useState(null);
  const [recordingTime, setRecordingTime] = useState(null);

  useEffect(() => {
    screenSizeDetector();

    window.addEventListener("resize", screenSizeDetector);
  }, []);

  useEffect(() => {
    const usersRef = firebase.firestore().collection("users");

    const change = async (user) => {
      if (user && firebase.auth().currentUser) {
        usersRef.doc(user.uid).onSnapshot((document) => {
          if (document.exists) {
            const userData = document.data();
            setUser(userData);
          }
        });
      } else {
        setUser("");
      }
    };

    firebase.auth().onAuthStateChanged((user) => {
      change(user);
    });
  }, []);

  const screenSizeDetector = () => {
    if (window.innerWidth > 900 && window.innerWidth < 1000) {
      setVersion("Tablet");
      setIsDesktop(true);
    } else if (window.innerWidth <= 900) {
      setVersion("Mobile");
      setIsDesktop(false);
    } else {
      setVersion("Desktop");
      setIsDesktop(true);
    }
  };

  if (user === null) {
    return null;
  }

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/about"
          render={() =>
            isDesktop ? <About user={user} /> : <AboutMobile user={user} />
          }
        />

        <Route
          exact
          path="/sign-in"
          render={() =>
            isDesktop ? <Redirect to="/" /> : <SignInMobile user={user} />
          }
        />

        {/* <Route
          exact
          path="/chat"
          render={() =>
            isDesktop ? <Chat user={user} /> : <ChatMobile user={user} />
          }
        /> */}

        {user ? (
          <Route
            exact
            path="/upgrade"
            render={() =>
              isDesktop ? <Redirect to="/" /> : <UpgradeMobile user={user} />
            }
          />
        ) : null}

        {/* {user ? (
          <Route
            exact
            path="/cloning"
            render={() =>
              isDesktop ? (
                <Cloning
                  recordingText={recordingText}
                  recordingTime={recordingTime}
                  user={user}
                />
              ) : (
                <CloningMobile
                  recordingText={recordingText}
                  recordingTime={recordingTime}
                  user={user}
                />
              )
            }
          />
        ) : null} */}

        {/* {user ? (
          <Route
            exact
            path="/chat-intro"
            render={() =>
              isDesktop ? (
                <ChatIntro user={user} />
              ) : (
                <ChatIntroMobile user={user} />
              )
            }
          />
        ) : null} */}

        <Route
          exact
          path="/terms-of-use"
          render={() =>
            isDesktop ? <Terms user={user} /> : <TermsMobile user={user} />
          }
        />

        <Route
          exact
          path="/faq"
          render={() =>
            isDesktop ? <FAQ user={user} /> : <FAQMobile user={user} />
          }
        />

        {/* {user ? (
          <Route
            exact
            path="/settings"
            render={() =>
              isDesktop ? (
                <Settings user={user} />
              ) : (
                <SettingsMobile user={user} />
              )
            }
          />
        ) : null} */}

        <Route
          exact
          path="/contact"
          render={() =>
            isDesktop ? <Contact user={user} /> : <ContactMobile user={user} />
          }
        />

        <Route
          exact
          path="/privacy"
          render={() =>
            isDesktop ? <Privacy user={user} /> : <PrivacyMobile user={user} />
          }
        />

        {/* <Route
          exact
          path="/explore"
          render={() =>
            isDesktop ? <Redirect to="/" /> : <WelcomeMobile user={user} />
          }
        /> */}

        <Route
          exact
          path="/"
          render={() =>
            isDesktop ? <Landing user={user} /> : <LandingMobile user={user} />
          }
        />

        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default withRouter(App);
