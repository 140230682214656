import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import "../index.css";
import { SkyLightStateless } from "react-skylight";

const SuggestAvatar = ({ isOpen, setIsOpen, name, setName, submitName }) => {
  return (
    <SkyLightStateless
      hideOnOverlayClicked={true}
      onOverlayClicked={() => {
        setIsOpen(false);
        setLoginVisible(false);
      }}
      onCloseClicked={() => {
        setIsOpen(false);
        setLoginVisible(false);
      }}
      isVisible={isOpen}
      overlayStyles={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99,
        backgroundColor: "#181616",
        opacity: 0.8,
      }}
      dialogStyles={{
        zIndex: 9000,
        position: "fixed",
        width: "420px",
        minHeight: "60px",
        marginTop: "7%",
        top: 0,
        height: "420",
        backgroundColor: "transparent",
        border: "0px solid black",
        boxShadow: "none",
        left: 0,
        right: "15%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      closeButtonStyle={{
        color: "white",
        right: -180,
        position: "absolute",
        marginTop: 15,
        zIndex: 9000,
      }}
    >
      <div
        style={{
          paddingBottom: 60,
          width: 600,
          backgroundColor: "black",
          borderRadius: 9,
        }}
        className="backy60"
      >
        <MDBRow>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontWeight: "900",
                fontFamily: "PlusJSExtraBold",
                fontSize: 25,
                opacity: 1,
                lineHeight: 1.2,
                textAlign: "center",
                marginTop: 40,
                width: 400,
              }}
            >
              Suggest an Avatar
            </p>
          </MDBCol>

          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontFamily: "PlusJSBold",
                fontSize: 16,
                opacity: 1,
                lineHeight: 1.2,
                textAlign: "center",
                marginTop: 0,
                width: 400,
                cursor: "pointer",
              }}
            >
              Suggest an avatar or person we should create.
            </p>
          </MDBCol>
          <MDBCol size="12">
            <p
              style={{
                color: "white",
                fontFamily: "PlusJSMedium",
                marginLeft: 100,
                fontSize: 12,
                marginTop: 12,
              }}
            >
              Name
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <input
              style={{
                height: 45,
                width: 400,
                marginLeft: 0,
                border: "1px solid #696969	",
                borderRadius: 4,
                display: "inline-block",
                color: "white",
                marginTop: -9,
                backgroundColor: "#2b2b2b",
                fontFamily: "PlusJSSemiBold",
                paddingLeft: 17,
                fontSize: 13,
              }}
              value={name}
              name="Email"
              onChange={(event) => setName(event.target.value)}
              placeholder="Name of the person"
            />
          </MDBCol>

          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                height: 45,
                width: 400,
                marginLeft: 0,
                borderRadius: 4,
                display: "inline-block",
                color: "white",
                marginTop: 20,
                backgroundColor: "white",
                fontFamily: "PlusJSSemiBold",
                paddingLeft: 17,
                fontSize: 13,
                cursor: "pointer",
              }}
              onClick={() => {
                if (name) {
                  submitName(name);
                }
              }}
              className="d-flex justify-content-center"
            >
              <p
                style={{
                  color: "black",
                  marginTop: 10,
                  fontFamily: "PlusJSBold",
                  fontSize: 16,
                }}
              >
                Submit an avatar
              </p>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </SkyLightStateless>
  );
};

export default SuggestAvatar;
