import React from "react";
import "../index.css";
import Footer from "../Layout/Footer";
import { MDBCol, MDBRow } from "mdbreact";
import MenuDesktop from "../Layout/MenuDesktop";

const Contact = ({ user }) => {
  return (
    <>
      <MDBCol
        style={{
          width: "105vw",
          overflowX: "hidden",
          paddingBottom: "20vh",
          color: "white",
          backgroundColor: "#101340",
        }}
        size="12"
      >
        <MenuDesktop user={user} />
        <MDBRow style={{ marginTop: 120 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 35,
                fontWeight: "600",
                marginLeft: 120,
              }}
            >
              Contact us
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -15,
                width: "60%",
                lineHeight: 1.7,
              }}
            >
              If you're experiencing any problems regarding your payment or
              issues with selling simply email help@bollywoodai.app One of our
              team members will reach out shortly.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <Footer />
    </>
  );
};

export default Contact;
