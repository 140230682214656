import React, { Component, useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBAnimation,
  MDBIcon,
  MDBTooltip,
} from "mdbreact";
import { firebase } from "../../firebase/config";
import "../index.css";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiHomeSmile2Line,
  RiApps2Line,
  RiStore2Line,
  RiMoneyDollarBoxLine,
  RiSettings4Line,
  RiLogoutBoxLine,
  RiKey2Line,
  RiKeyFill,
  RiKey2Fill,
  RiTestTubeFill,
  RiArrowLeftLine,
} from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import { HiCash } from "react-icons/hi";
import { FaDotCircle } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { IoRadioSharp } from "react-icons/io5";
import { loadStripe } from "@stripe/stripe-js";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";

import { AiOutlineMail, AiOutlineWechat } from "react-icons/ai";
import { MdPersonOutline } from "react-icons/md";
import { RxPerson } from "react-icons/rx";
import mixpanel from "mixpanel-browser";

const CheckoutForm = ({ stripe, elements }) => {
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const handleSubmit = async (event) => {
    mixpanel.track(`Started Upgrade`);
    setLoading(true);
    const uid = firebase.auth().currentUser.uid;
    event.preventDefault();

    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        price: "price_1MtaZhCZJRqaK8xtPF0GtdIx",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        setLoading(false);
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  return (
    <form style={{ width: "80vw", height: 200 }} onSubmit={handleSubmit}>
      <MDBRow sstyle={{ width: "100%" }}>
        <MDBCol size="10">
          <p
            style={{
              fontFamily: "PlusJSMedium",
              color: "white",
              fontSize: 12,
              marginTop: 10,
              opacity: 0.7,
              width: "85vw",
              textAlign: "center",
            }}
          >
            By upgrading your membership to BanterAI, you will gain access to
            our exclusive chat and communication features with our avatars.
            Please note that after the end of each billing cycle, your card will
            be charged the upgraded membership fee until you cancel. We would
            like to remind you that by upgrading your membership, you agree to
            our terms of service.
          </p>
        </MDBCol>
      </MDBRow>
      <button
        style={{
          marginTop: 5,
          width: "100%",
          color: "white",
          borderRadius: 3,
          fontFamily: "PlusJSSemiBold",
          backgroundColor: "#77FFBA",
          border: "0px solid white",
          fontSize: 14,
          height: 40,
          textAlign: "center",
          paddingTop: 15,
        }}
        className="d-flex justify-content-center"
        type="submit"
        disabled={false}
      >
        {loading ? (
          <ThreeDots
            type="ThreeDots"
            style={{ marginBottom: -10 }}
            color="black"
            height={8}
            timeout={200000}
            width={100}
          />
        ) : (
          <p
            style={{
              marginTop: -6,
              color: "black",
              fontFamily: "PlusJSBold",
            }}
          >
            Checkout
          </p>
        )}
      </button>
    </form>
  );
};

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

const liveKey = "pk_live_sQnEHhDozaINr1hQ31f16xgn00h1XMM1ha";
const testKey = "pk_test_JJR1NrgLevxae7dTR8GPktY200aaS2ubWC";
const stripePromise = loadStripe(liveKey);

const UpgradeMobile = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState("Settings");
  const [isNavbar, setIsNavbar] = useState(true);
  const [signUp, setSignUp] = useState(true);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [email, setemail] = useState("");
  const [uid, setUID] = useState("");
  const [unread, setUnread] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "visible";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "#2b2b2b",

        height: "110vh",
        overflowX: "hidden",
      }}
      className="backy60"
    >
      <MDBRow>
        <MDBCol className="d-flex justify-content-center" size="12">
          <NavLink to="/" exact>
            <p
              style={{
                color: "white",
                fontWeight: "900",
                fontFamily: "Grifter",
                fontSize: 25,
                opacity: 1,
                lineHeight: 1.2,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Upgrade to Call
            </p>
          </NavLink>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <div>
            <p
              style={{
                color: "white",
                fontSize: 18,
                marginTop: 10,
                fontFamily: "PlusJSBold",
                textAlign: "center",
              }}
            >
              {" "}
              Get unlimited access to 50+ world class
              <br />
              experts and celebrities
            </p>
            <MDBRow>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    color: "white",
                    fontSize: 14,
                    marginTop: 0,
                    fontFamily: "PlusJSMedium",
                    opacity: 0.6,
                  }}
                >
                  Expert conversations with interesting people.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 10, paddingLeft: "15%" }}>
              <MDBCol size="1">
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 20,
                    width: 20,
                    backgroundColor: "#77FFBA",
                    borderRadius: 3,
                    marginTop: 2,
                  }}
                >
                  <TiTick style={{ color: "black", marginTop: 1 }} />
                </div>
              </MDBCol>
              <MDBCol size="10">
                <p
                  style={{
                    color: "white",
                    fontSize: 14,
                    marginTop: 1,
                    marginLeft: 9,
                    fontFamily: "PlusJSMedium",
                  }}
                >
                  Access to entire catalogue
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 0, paddingLeft: "15%" }}>
              <MDBCol size="1">
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 20,
                    width: 20,
                    backgroundColor: "#77FFBA",
                    borderRadius: 3,
                    marginTop: 2,
                  }}
                >
                  <TiTick style={{ color: "black", marginTop: 1 }} />
                </div>
              </MDBCol>
              <MDBCol size="10">
                <p
                  style={{
                    color: "white",
                    fontSize: 14,
                    marginTop: 1,
                    marginLeft: 9,
                    fontFamily: "PlusJSMedium",
                  }}
                >
                  Expert advice anytime
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 0, paddingLeft: "15%" }}>
              <MDBCol size="1">
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 20,
                    width: 20,
                    backgroundColor: "#77FFBA",
                    borderRadius: 3,
                    marginTop: 2,
                  }}
                >
                  <TiTick style={{ color: "black", marginTop: 1 }} />
                </div>
              </MDBCol>
              <MDBCol size="10">
                <p
                  style={{
                    color: "white",
                    fontSize: 14,
                    marginTop: 1,
                    marginLeft: 9,
                    fontFamily: "PlusJSMedium",
                  }}
                >
                  Unlimited calls with experts
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 0, paddingLeft: "15%" }}>
              <MDBCol size="1">
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 20,
                    width: 20,
                    backgroundColor: "#77FFBA",
                    borderRadius: 3,
                    marginTop: 2,
                  }}
                >
                  <TiTick style={{ color: "black", marginTop: 1 }} />
                </div>
              </MDBCol>
              <MDBCol size="10">
                <p
                  style={{
                    color: "white",
                    fontSize: 14,
                    marginTop: 1,
                    marginLeft: 9,
                    fontFamily: "PlusJSMedium",
                  }}
                >
                  Mobile chat and calling
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 0, paddingLeft: "15%" }}>
              <MDBCol size="1">
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 20,
                    width: 20,
                    backgroundColor: "#77FFBA",
                    borderRadius: 3,
                    marginTop: 2,
                  }}
                >
                  <TiTick style={{ color: "black", marginTop: 1 }} />
                </div>
              </MDBCol>
              <MDBCol size="10">
                <p
                  style={{
                    color: "white",
                    fontSize: 14,
                    marginTop: 1,
                    marginLeft: 9,
                    fontFamily: "PlusJSMedium",
                  }}
                >
                  Join a community
                </p>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
        <MDBCol size="12">
          <MDBRow className="d-flex">
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  color: "white",
                  fontSize: 23,
                  marginTop: 16,
                  fontFamily: "PlusJSBold",
                }}
              >
                $5/month
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  color: "white",
                  fontSize: 13,
                  marginTop: -16,
                  fontFamily: "PlusJSMedium",
                  opacity: 0.6,
                }}
              >
                Unlimited access to all avatars
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  color: "white",
                  fontSize: 13,
                  marginBottom: 0,
                  fontFamily: "PlusJSMedium",
                  opacity: 0.9,
                }}
              >
                Get started today
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginTop: 5 }}>
            <MDBCol className="d-flex justify-content-center" size="12">
              <Elements stripe={stripePromise}>
                <InjectedCheckoutForm />
              </Elements>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default UpgradeMobile;
