import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { firebase } from "../../firebase/config";
import RowMobile from "./RowMobile";
import MobileFooter from "../Layout/mobileFooter";
import useGeoLocation from "react-ipgeolocation";

import MobileTopMenu from "../Layout/mobileTopMenu";

const CloningMobile = ({ user }) => {
  const [avatars, setAvatars] = useState([]);
  const [image, setImage] = useState(null);
  const [callingVisible, setCallingVisible] = useState(false);
  const [calling, setCalling] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [loginVisible, setLoginVisible] = useState(false);
  const [popular, setPopular] = useState([]);
  const [recent, setRecent] = useState([]);
  const [subscribed, setSubscribed] = useState(false);
  const location = useGeoLocation();

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      firebase
        .firestore()
        .collection("customers")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initActive = [];
            querySnapshot.forEach((doc) => {
              initActive.push(doc.data());
            });
            if (initActive.length > 0) {
              setSubscribed(true);
            }
          }
        });
    }
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("avatars")
      .get()
      .then((querySnapshot) => {
        let popularEmpty = [];
        let recentEmpty = [];
        let empty = [];
        querySnapshot.forEach((doc) => {
          empty.push(doc.data());
          if (doc.data().Row === "Recent") {
            recentEmpty.push(doc.data());
          } else if (doc.data().Row === "Popular") {
            popularEmpty.push(doc.data());
          }
        });
        setAvatars(empty);
        setPopular(popularEmpty);
        setRecent(recentEmpty);
      });
  }, []);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#2b2b2b",
          color: "white",
        }}
      >
        <MDBRow>
          <MDBCol size="12">
            <MobileTopMenu subscribed={subscribed} user={user} />
          </MDBCol>
        </MDBRow>

        <MDBRow
          style={{
            paddingBottom: 0,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            marginTop: 70,
            backgroundColor: "#2b2b2b",
          }}
        >
          <MDBCol size="12">
            <div
              className="backy60 d-flex justify-content-center"
              style={{
                width: "100vw",
                objectFit: "cover",
                opacity: 1,
                background: "transparent",
              }}
            >
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  top: 60,
                  lineHeight: 1.4,
                  fontSize: 22,
                  color: "white",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: 0,
                  width: "100%",
                }}
              >
                Call up your favorite celebrities
                <br />
                Scary realistic AI (BETA)
              </p>
            </div>
          </MDBCol>
          <MDBCol size="12">
            <div
              className="backy60 d-flex justify-content-center"
              style={{
                width: "100vw",
                objectFit: "cover",
                opacity: 1,
                background: "transparent",
              }}
            >
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  top: 60,
                  lineHeight: 1.6,
                  fontSize: 13,
                  color: "white",
                  textAlign: "center",
                  marginTop: 0,
                  opacity: 0.7,
                  width: "85%",
                }}
              >
                There might be a delay (like 3-5 seconds) between when
                <br />
                you say something and when it responds. Also there
                <br />
                might be weird stuff going on with dialogue.. we're working on
                it.😂
              </p>
            </div>
          </MDBCol>
        </MDBRow>

        <RowMobile
          setLoginVisible={setLoginVisible}
          subscribed={subscribed}
          user={user}
          color="Dark"
          Category="Most Popular"
          avatars={
            location.country === "JP"
              ? popular
                  .sort((a, b) => a.Rank - b.Rank)
                  .slice(0, 4)
                  .concat(
                    avatars.filter((avatar) => avatar.Country === "Japan")
                  )
                  .concat(popular.sort((a, b) => a.Rank - b.Rank).slice(4, 8))
              : popular.sort((a, b) => a.Rank - b.Rank).slice(0, 12)
          }
        />

        <MDBRow className="d-flex justify-content-center">
          <MDBCol className="d-flex justify-content-center" size="12">
            <NavLink
              to={
                firebase.auth().currentUser
                  ? subscribed
                    ? "/explore"
                    : "/upgrade"
                  : "/sign-in"
              }
              exact
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  cursor: "pointer",
                  height: 50,
                  width: "80vw",
                  border: "1px solid white",
                  alignSelf: "center",
                  borderRadius: 100,
                  opacity: 0.7,
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontFamily: "PlusJSSemiBold",
                    marginTop: 12,
                    textAlign: "center",
                  }}
                >
                  Get unlimited access to 50+ voices
                </p>
              </div>
            </NavLink>
          </MDBCol>
        </MDBRow>

        <MobileFooter />
      </div>
    </>
  );
};

export default withRouter(CloningMobile);
