import React, { useEffect, useState, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { IoCall } from "react-icons/io5";
import { withRouter } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { firebase } from "../../firebase/config";
import { SkyLightStateless } from "react-skylight";
import Call from "../Layout/Call";
import MiniRow from "./MiniRow";
import mixpanel from "mixpanel-browser";

import SpeechRecognition from "react-speech-recognition";
import useFirebaseStorage from "../../hooks/useFirebaseStorage";

const Row = ({
  avatars,
  color,
  Category,
  user,
  setLoginVisible,
  allAvatars,
  setUpgradeVisible,
  subscribed,
}) => {
  const { uploadBlob, uploadFile } = useFirebaseStorage();

  const endCallRef = useRef();

  const [image, setImage] = useState(null);
  const [callingVisible, setCallingVisible] = useState(false);
  const [calling, setCallingHook] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [visible, setVisible] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [timer, setTimer] = useState(null);

  const [conversationClips, setConversationClips] = useState([]);

  const handleUpload = async (payload) => {
    return null;
  };

  return (
    <>
      <MDBRow
        style={{
          marginTop: 50,
          overflowY: "hidden",
          paddingTop: 24,
          paddingBottom: 20,
          overflowX: "hidden",
          backgroundColor: "#101340",
        }}
        className="backy60"
      >
        <MDBRow
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            width: 1300,
            alignSelf: "center",
            margin: "20px auto",
          }}
        >
          {avatars.map((seller) => (
            <MDBCol key={seller.name} size="3" style={{ paddingBottom: 50 }}>
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <img
                    onClick={() => {
                      window.location.href = "https://" + seller.link;
                    }}
                    src={seller.image}
                    style={{
                      width: 270,
                      height: 270,
                      objectFit: "cover",
                      borderRadius: 200,
                      cursor: "pointer",
                      border:
                        seller.Free === true
                          ? "6px solid #1bd741"
                          : "6px solid grey",
                      opacity: seller.Free === true ? 1 : 0.5,
                    }}
                  />
                </MDBCol>
                <MDBCol
                  style={{
                    overflowY: "hidden",
                    zIndex: 0,
                    marginTop: -30,
                  }}
                  className="d-flex justify-content-center"
                  size="12"
                >
                  <div
                    onClick={() => {
                      window.location.href = "https://" + seller.link;
                    }}
                    style={{
                      fontSize: 19,
                      fontFamily: "PlusJSBold",
                      textAlign: "center",
                      alignSelf: "center",
                      height: 60,
                      width: 60,
                      backgroundColor:
                        seller.Free === true ? "#1bd741" : "#1bd741",
                      borderRadius: 200,
                      cursor: "pointer",
                    }}
                  >
                    {seller.Free === true ? (
                      <img
                        src={require("./WhatsappIcon.png")}
                        style={{ marginTop: 10, height: 40, width: "autoo" }}
                      />
                    ) : (
                      <FaLock
                        style={{
                          marginTop: 18,
                          fontSize: 25,
                        }}
                      />
                    )}
                  </div>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontSize: 19,
                      marginTop: 20,
                      fontFamily: "PlusJSBold",
                      color: "white",
                    }}
                  >
                    {seller.name[0].toUpperCase() + seller.name.substring(1)}
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <div
                    style={{
                      height: 53,
                      width: 250,
                      backgroundColor: "#1bd741",
                      borderRadius: 200,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.location.href = "https://" + seller.link;
                    }}
                    className="d-flex justify-content-center"
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: 13,
                        fontFamily: "PlusJSBold",
                      }}
                    >
                      <img
                        src={require("./WhatsappIcon.png")}
                        style={{
                          height: 34,
                          width: "auto",
                          marginTop: -3,
                        }}
                      />{" "}
                      Chat on WhatsApp
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          ))}
        </MDBRow>
      </MDBRow>
    </>
  );
};

export default withRouter(Row);
