import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import MobileFooter from "../Layout/mobileFooter";
import MobileTopMenu from "../Layout/mobileTopMenu";

const AboutMobile = ({ user }) => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#101340",

          color: "white",
        }}
      >
        <MDBRow>
          <MDBCol size="12">
            <MobileTopMenu user={user} />
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 150 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 25,
                fontWeight: "600",
                marginLeft: "5.5%",
              }}
            >
              About BollywoodAI
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              BollywoodAI is a unique and innovative website that offers its
              users the opportunity to have whatsapp chats with cloned versions
              of bollywood icons. BollywoodAI makes it possible to connect with
              virtual clones that can respond to your questions and statements
              in real-time.
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: 15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              With BollywoodAI, you can engage in a wide range of conversations
              with your chosen bollywood star, from discussing their latest
              projects and personal lives to getting their opinions on current
              events and social issues. The cloned personalities are designed to
              mimic the voices and mannerisms of the real-life individuals,
              creating an incredibly realistic and immersive experience for
              users.
            </p>

            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: 15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              BollywoodAI's advanced AI technology ensures that the
              conversations are both engaging and responsive, allowing users to
              have personalized and authentic interactions with their chosen
              bollywood stars.
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: 15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              At BollywoodAI, we believe that the possibilities for this
              technology are endless. Join the conversation today and experience
              the future of AI-powered entertainment.
            </p>
          </MDBCol>
        </MDBRow>

        <MobileFooter />
      </div>
    </>
  );
};

export default AboutMobile;
