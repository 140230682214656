import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import "./index.css";
import App from "./App";
import { UserProvider } from "./UserContext";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";

Sentry.init({
  dsn: "https://44f9a1ebeba34ebcb2f846fa719b9ebe@o4504939617452032.ingest.sentry.io/4504939618566144",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
