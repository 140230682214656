import React from "react";
import "../index.css";
import Footer from "../Layout/Footer";
import { MDBRow, MDBCol } from "mdbreact";
import MenuDesktop from "../Layout/MenuDesktop";

const FAQ = ({ user }) => {
  return (
    <>
      <MDBCol
        style={{
          backgroundColor: "#101340",
          width: "105vw",
          overflowX: "hidden",
          paddingBottom: "20vh",
          color: "white",
        }}
        size="12"
      >
        <MenuDesktop user={user} />
        <MDBRow style={{ marginTop: 130 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 35,
                marginLeft: 120,
              }}
            >
              FAQ
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -15,
                width: "60%",
              }}
            >
              1. How much does it cost for users?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -9,
                width: "60%",
              }}
            >
              It's completely free.
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -15,
                width: "60%",
              }}
            >
              2. I’ve seen an avatar I want to talk to. How do I do it?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -9,
                width: "60%",
              }}
            >
              It’s as simple as pressing the Whatsapp button. That will redirect
              you to a WhatsApp chat.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -15,
                width: "60%",
              }}
            >
              3. I want to suggest a new character how do I do it?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -9,
                width: "60%",
              }}
            >
              Join our discord community and suggest characters in the
              characters channel.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -15,
                width: "60%",
              }}
            >
              4. How do I share an avatar?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                marginLeft: 120,
                marginTop: -9,
                width: "60%",
              }}
            >
              Share an avatar by sharing the contact with your friends in
              WhatsApp.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <Footer />
    </>
  );
};

export default FAQ;
