import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { firebase } from "../../firebase/config";
import RowMobile from "./RowMobile";
import MobileFooter from "../Layout/mobileFooter";
import useGeoLocation from "react-ipgeolocation";
import { Analytics } from "@vercel/analytics/react";

import MobileTopMenu from "../Layout/mobileTopMenu";

const LandingMobile = ({ user }) => {
  const [avatars, setAvatars] = useState([]);
  const [image, setImage] = useState(null);
  const [callingVisible, setCallingVisible] = useState(false);
  const [calling, setCalling] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [loginVisible, setLoginVisible] = useState(false);
  const [popular, setPopular] = useState([]);
  const [recent, setRecent] = useState([]);
  const [subscribed, setSubscribed] = useState(false);
  const location = useGeoLocation();

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      firebase
        .firestore()
        .collection("customers")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initActive = [];
            querySnapshot.forEach((doc) => {
              initActive.push(doc.data());
            });
            if (initActive.length > 0) {
              setSubscribed(true);
            }
          }
        });
    }
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("avatars")
      .get()
      .then((querySnapshot) => {
        let popularEmpty = [];
        let recentEmpty = [];
        let empty = [];
        querySnapshot.forEach((doc) => {
          empty.push(doc.data());
          if (doc.data().Row === "Recent") {
            recentEmpty.push(doc.data());
          } else if (doc.data().Row === "Popular") {
            popularEmpty.push(doc.data());
          }
        });
        setAvatars(empty);
        setPopular(popularEmpty);
        setRecent(recentEmpty);
      });
  }, []);

  return (
    <>
      <Analytics />

      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#101340",
          color: "white",
        }}
      >
        <MDBRow>
          <MDBCol size="12">
            <MobileTopMenu subscribed={subscribed} user={user} />
          </MDBCol>
        </MDBRow>

        <MDBRow
          style={{
            paddingBottom: 0,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            marginTop: 70,
            backgroundColor: "#101340",
          }}
        >
          <MDBCol size="12">
            <div
              className="backy60 d-flex justify-content-center"
              style={{
                width: "100vw",
                objectFit: "cover",
                opacity: 1,
                background: "transparent",
              }}
            >
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  top: 60,
                  lineHeight: 1.4,
                  fontSize: 22,
                  color: "white",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: 0,
                  width: "90%",
                }}
              >
                Insanely realistic WhatsApp Chat & Voice with Bollywood's
                biggest stars
              </p>
            </div>
          </MDBCol>
          <MDBCol size="12">
            <div
              className="backy60 d-flex justify-content-center"
              style={{
                width: "100vw",
                objectFit: "cover",
                opacity: 1,
                background: "transparent",
              }}
            >
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  top: 60,
                  lineHeight: 1.6,
                  fontSize: 15,
                  color: "white",
                  textAlign: "center",
                  marginTop: 0,
                  opacity: 0.7,
                  width: "85%",
                }}
              >
                Chat with the biggest bollywood stars in Hindi
                <br /> with voice notes and more for free.
              </p>
            </div>
          </MDBCol>
        </MDBRow>

        <RowMobile
          setLoginVisible={setLoginVisible}
          subscribed={subscribed}
          user={user}
          color="Dark"
          Category="Most Popular"
          avatars={[
            {
              name: "Salman Khan",
              Free: true,
              image:
                "https://imagevars.gulfnews.com/2023/04/07/Salman-Khan-_1875a92c868_large.jpg",
              link: "wa.link/wxcok4",
            },
            {
              name: "Shah Rukh Khan",
              Free: true,
              image:
                "https://img.mensxp.com/media/content/2023/Feb/7---credit---Yash-Raj-Films_63e8ce1fece61.jpeg",
              link: "wa.link/supgzu",
            },
            {
              name: "Priyanka Chopra",
              Free: true,
              image:
                "https://m.media-amazon.com/images/M/MV5BMjAxNzUwNjExOV5BMl5BanBnXkFtZTcwNDUyMTUxNw@@._V1_.jpg",
              link: "wa.link/mt5pgv",
            },
            {
              name: "Hrithik Roshan",
              Free: true,
              image:
                "https://miro.medium.com/v2/resize:fit:828/format:webp/1*gWiAKd-4ZM3H1dwXyo64-Q.jpeg",
              link: "wa.link/1ywuz1",
            },
            {
              name: "Deepika Padukone",
              Free: true,
              image:
                "https://miro.medium.com/v2/resize:fit:828/format:webp/1*GpmmiCuMEbOWVUx3s6Pq8g.jpeg",
              link: "wa.link/jynk5n",
            },
            {
              name: "Akshay Kumar",
              Free: true,
              image:
                "https://miro.medium.com/v2/resize:fit:828/format:webp/1*a75jBTVEZK6M1sn_ukV0-w.jpeg",
              link: "wa.link/vl4sgh",
            },
            {
              name: "Alia Bhatt",
              Free: true,
              image:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/Alia_Bhatt_at_Berlinale_2022_Ausschnitt.jpg/640px-Alia_Bhatt_at_Berlinale_2022_Ausschnitt.jpg",
              link: "wa.link/54hdqn",
            },
            {
              name: "Shraddha Kapoor",
              Free: true,
              image:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Shraddha_Kapoor_in_Red_%28cropped%29.jpg/220px-Shraddha_Kapoor_in_Red_%28cropped%29.jpg",
              link: "wa.link/jgs4lz",
            },
          ]}
        />

        <MobileFooter />
      </div>
    </>
  );
};

export default withRouter(LandingMobile);
