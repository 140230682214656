import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import "../index.css";
import { SkyLightStateless } from "react-skylight";

const RedirectDesktop = ({ isOpen, setIsOpen }) => {
  return (
    <SkyLightStateless
      hideOnOverlayClicked={true}
      onOverlayClicked={() => {
        setIsOpen(false);
        setLoginVisible(false);
      }}
      onCloseClicked={() => {
        setIsOpen(false);
        setLoginVisible(false);
      }}
      isVisible={isOpen}
      overlayStyles={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99,
        backgroundColor: "#181616",
        opacity: 0.8,
      }}
      dialogStyles={{
        zIndex: 9000,
        position: "fixed",
        width: "100vw",
        minHeight: "60px",
        marginTop: "25%",
        top: 0,
        paddingBottom: 10,
        backgroundColor: "transparent",
        border: "0px solid black",
        boxShadow: "none",
        left: "6%",
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      closeButtonStyle={{
        color: "white",
        right: -180,
        position: "absolute",
        marginTop: 15,
        zIndex: 9000,
      }}
    >
      <div
        style={{
          paddingBottom: 40,
          width: "80vw",
          backgroundColor: "#2b2b2b",
          borderRadius: 9,
        }}
        className="backy60"
      >
        <MDBRow>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontWeight: "900",
                fontFamily: "PlusJSExtraBold",
                fontSize: 20,
                opacity: 1,
                lineHeight: 1.2,
                textAlign: "center",
                marginTop: 40,
                width: 400,
              }}
            >
              Try it on desktop
            </p>
          </MDBCol>

          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontFamily: "PlusJSBold",
                fontSize: 14,
                opacity: 1,
                lineHeight: 1.7,
                textAlign: "center",
                marginTop: 0,
                width: "80%",
                cursor: "pointer",
              }}
            >
              We are launching a mobile app in the next few days. In the
              meantime feel free to try us on desktop.
            </p>
          </MDBCol>
        </MDBRow>
      </div>
    </SkyLightStateless>
  );
};

export default RedirectDesktop;
