import React, { useEffect, useState } from "react";
import "../index.css";
import MobileFooter from "../Layout/mobileFooter";
import { MDBRow, MDBCol } from "mdbreact";
import MobileTopMenu from "../Layout/mobileTopMenu";
import { NavLink, withRouter } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ClipMobile = ({ user }) => {
  const [audio, setAudio] = useState(null);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const image = searchParams.get("image");
    const audio = searchParams.get("audio");
    const name = searchParams.get("name");

    setImage(image);
    setAudio(audio);
    setName(name);
  }, []);

  if (!audio) {
    return null;
  }
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#2b2b2b",
          color: "white",
        }}
      >
        <MDBRow>
          <MDBCol size="12">
            <MobileTopMenu user={user} />
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 90 }}>
          <MDBCol className="d-flex justify-content-center" size="12">
            <img
              src={image}
              style={{
                height: 120,
                width: 120,
                borderRadius: 300,
                boxShadow: `0px 0px 20px 20px #38ef7d`,
                objectFit: "cover",
                alignSelf: "center",
                marginTop: 10,
              }}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <MDBRow>
              <MDBCol size="9">
                <input
                  type="text"
                  value={window.location.href}
                  style={{
                    width: "70%",
                    marginTop: 20,
                    height: 40,
                    borderRadius: 6,
                    border: "none",
                    paddingLeft: 10,
                    marginLeft: 20,
                    fontSize: 15,
                    marginTop: 70,
                    fontFamily: "PlusJSMedium",
                    border: "1px solid white",
                    color: "white",
                    backgroundColor: "#2b2b2b",
                  }}
                />
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="2">
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setCopied(true)}
                >
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      width: 400,
                      marginTop: 20,
                      marginLeft: -90,
                      height: 40,
                      borderRadius: 6,
                      border: "none",
                      paddingLeft: 6,
                      fontSize: 15,
                      marginTop: 70,
                      fontFamily: "PlusJSBold",
                      color: "white",
                      backgroundColor: "white",
                      border: "2px solid white",
                      cursor: "pointer",
                    }}
                  >
                    <p style={{ marginTop: 5, color: "black" }}>
                      {copied ? "Copied!" : "Copy Link"}
                    </p>
                  </div>
                </CopyToClipboard>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{ marginTop: 20, fontFamily: "PlusJSBold", fontSize: 20 }}
            >
              Share your call with {name}
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                marginTop: 10,
                fontFamily: "PlusJSMedium",
                fontSize: 17,
                opacity: 0.7,
                textAlign: "center",
              }}
            >
              Talk to 100+ celebrities and characters.
              <br />
              <NavLink
                to="/"
                style={{
                  color: "#38ef7d",
                  marginLeft: 9,
                }}
                exact
              >
                Join BanterAI Here.
              </NavLink>
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <audio style={{ marginTop: 20, width: "90%" }} controls>
              <source src={audio} type="audio/mp3" />
            </audio>
          </MDBCol>
        </MDBRow>

        <MobileFooter />
      </div>
    </>
  );
};

export default withRouter(ClipMobile);
