import React, { useState, useEffect, useRef } from "react";

import { firebase } from "../../firebase/config";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "./index.css";
import createSpeechServicesPonyfill from "web-speech-cognitive-services";
import { MDBRow, MDBCol } from "mdbreact";
import { ImPhoneHangUp } from "react-icons/im";
import { v4 as uuidv4 } from "uuid";
import { useStopwatch } from "react-timer-hook";
import fetch from "isomorphic-fetch";
import { BsFillMicMuteFill } from "react-icons/bs";
import { BsFillMicFill } from "react-icons/bs";
import { GiTrumpet } from "react-icons/gi";
import mixpanel from "mixpanel-browser";

// const dev = "http://localhost:8000/";
// const prod = "https://rogueai.herokuapp.com/";
const prod = "https://us-central1-rogueai.cloudfunctions.net/widgets/";
const SUBSCRIPTION_KEY = process.env.REACT_APP_SPEECH_KEY;
const REGION = "eastus";

const { SpeechRecognition: AzureSpeechRecognition } =
  createSpeechServicesPonyfill({
    credentials: {
      region: REGION,
      subscriptionKey: SUBSCRIPTION_KEY,
    },
  });
SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
const recognition = SpeechRecognition.getRecognition();

const CallMobile = ({
  image,
  sellerName,
  calling,
  setCalling,
  uid,
  voiceID,
  prompt,
  playEndSound,
  callingSound,
  setCallingSound,
}) => {
  const {
    resetTranscript,
    browserSupportsSpeechRecognition,
    interimTranscript,
    finalTranscript,

    listening,
  } = useSpeechRecognition();

  console.log("listening", listening);
  console.log("finalTranscript", finalTranscript);

  const { seconds, minutes, start, reset } = useStopwatch({
    autoStart: false,
  });

  const [conversationId, setConversationId] = useState("");

  const [generating, setGenerating] = useState(false);
  const [text, setText] = useState("Calling...");
  const [audioData, setAudioData] = useState([]);
  const [audioChunks, setAudioChunks] = useState([]);
  const [currentChunk, setCurrentChunk] = useState(null);
  const [callStarted, setCallStarted] = useState(false);
  const [finishedSpeaking, setFinishedSpeaking] = useState(false);

  const [gainNode, setGainNode] = useState(null);

  const [onboarded, setOnboarded] = useState(null);

  const [aiSpeech, setAISpeech] = useState(false);
  const [shadowRadius, setShadowRadius] = useState(20);
  const [started, setStarted] = useState(false);

  // Send event to mixpanel
  useEffect(() => {
    if (calling) {
      const startTime = Date.now();
      mixpanel.time_event(`Call duration`);

      return () => {
        const durationSeconds = Math.floor((Date.now() - startTime) / 1000);
        mixpanel.track(`Call with duration`, {
          duration: durationSeconds,
          name: sellerName,
        });
      };
    }
  }, [calling]);

  useEffect(() => {
    if (calling && uid) {
      const interval = setInterval(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .update({
            timer: firebase.firestore.FieldValue.increment(1),
          });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [calling, uid]);

  useEffect(() => {
    if (!calling && currentChunk) {
      currentChunk.stop();
      setAudioChunks([]);
    }
  }, [calling]);

  useEffect(() => {
    if (listening && finalTranscript && aiSpeech) {
      // resetTranscript();
      stopListening();
    } else if (!listening && calling) {
      startListeningSpeech();
    }
  }, [aiSpeech]);

  useEffect(() => {
    if (aiSpeech) {
      if (!started) {
        setStarted(true);
        // startListening();
        startListeningSpeech();
        setText(null);
        if (callingSound) {
          callingSound.stop();
        }
        // backgroundRef.current.pause();
        start();
      }
    }
  }, [aiSpeech]);

  useEffect(() => {
    if (!calling) {
      stopSpeechRecognitionAndChunk();
    } else {
      startSpeechRecognitionIfSupported();
    }
  }, [calling]);

  useEffect(() => {
    if (calling && uid) {
      firebase.firestore().collection("users").doc(uid).update({
        firstPhoneCall: true,
      });
    } else {
      reset();

      setGenerating(false);
    }
  }, [calling, uid]);

  useEffect(() => {
    if (
      !currentChunk &&
      audioChunks.length > 0 &&
      !interimTranscript &&
      !finalTranscript &&
      calling
    ) {
      playAudio(audioChunks.shift(), calling);
    }
  }, [currentChunk, audioChunks, interimTranscript, finalTranscript, calling]);

  useEffect(() => {
    if (conversationId) {
      setTimeout(() => {
        triggerTTS("[begin]");
      }, 1000);
    }
  }, [conversationId]);

  useEffect(() => {
    let conversationId = uuidv4();
    setConversationId(conversationId);

    firebase
      .firestore()
      .collection("Conversations")
      .doc(conversationId)
      .set({ conversation: "" });
  }, []);

  function startSpeechRecognitionIfSupported() {
    const hasGivenMicrophonePermissionBefore =
      localStorage.getItem("hasGivenMicrophonePermissionBefore") === "true";

    if (
      browserSupportsSpeechRecognition &&
      !hasGivenMicrophonePermissionBefore
    ) {
      getMicrophoneAccess()
        .then(() => {
          // startListening();
          localStorage.setItem("hasGivenMicrophonePermissionBefore", "true");
        })
        .catch((error) => {
          window.location.reload();
        });
    }
  }

  async function endCall() {
    playEndSound();
    if (gainNode) {
      gainNode.gain.value = 0;
    }
    setCalling(false);
    setAudioChunks([]);
    setAudioData(null);
    setCurrentChunk(null);
    setConversationId("");
    stopSpeechRecognitionAndChunk();
  }

  function stopSpeechRecognitionAndChunk() {
    SpeechRecognition.abortListening();
    if (currentChunk) {
      currentChunk.stop();
    }
  }

  async function stopListening() {
    recognition.abort();
  }

  async function startListeningSpeech() {
    try {
      await SpeechRecognition.startListening({
        continuous: true,
        language: "en-US",
      });
    } catch (error) {
      console.error(error);

      if (listening) {
        await stopListening();
      }
    }
  }

  function getMicrophoneAccess() {
    return navigator.mediaDevices.getUserMedia({ audio: true });
  }

  // const startListening = async () => {
  //     // setListening(true);
  //     try {
  //         start();
  //         setText(null);
  //         await SpeechRecognition.startListening({
  //             continuous: true,
  //             language: "en-US",
  //         });
  //     } catch (error) {
  //         console.log("Error: ", error);
  //         if (listening) {
  //             await stopListening();
  //         }
  //     }
  // };

  const playAudio = (chunk, calling) => {
    if (calling && chunk && chunk.byteLength > 0) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const source = audioContext.createBufferSource();

      audioContext.decodeAudioData(chunk, function (buffer) {
        source.buffer = buffer;
        setAISpeech(true);

        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;

        const gainNode = audioContext.createGain();
        source.connect(gainNode);
        gainNode.connect(audioContext.destination);

        // Create low-pass filter
        const filter = audioContext.createBiquadFilter();
        filter.type = "lowpass";
        filter.frequency.setValueAtTime(2000, audioContext.currentTime);
        gainNode.connect(filter);
        filter.connect(audioContext.destination);

        setGainNode(gainNode);
        source.start(0);
        source.onended = () => {
          setCurrentChunk(null);
          setAISpeech(false);
          setFinishedSpeaking(false);

          resetTranscript();
        };
      });
      setCurrentChunk(source);
    } else {
      return null;
    }
  };

  const handleNewAudioChunk = (chunk) => {
    setAudioChunks([...audioChunks, chunk]);
  };

  const playNextAudio = (audioContext) => {
    if (audioData.length === 0) {
      return;
    }
    const source = audioContext.createBufferSource();
    source.buffer = audioData[0];
    source.connect(audioContext.destination);
    setAISpeech(GiTrumpet);
    source.start(0);
    source.onended = () => {
      setAudioData((prevAudioData) => prevAudioData.slice(1));
      playNextAudio(audioContext);
      setFinishedSpeaking(false);

      setAISpeech(false);
    };
  };

  const renderText = (text) => {
    if (text === "Calling...") {
      return "Calling...";
    } else {
      return (
        <>
          {" "}
          <span>{("0" + minutes).slice(-2)}</span>:
          <span>{("0" + seconds).slice(-2)}</span>
        </>
      );
    }
  };

  const triggerTTS = async (text) => {
    if (aiSpeech === false) {
      setGenerating(true);

      if (conversationId && calling) {
        const token = await firebase.auth().currentUser.getIdToken();
        fetch(prod + "webResponse", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: text,
            id: conversationId,
            voiceID: voiceID,
            voiceName: sellerName,
            prompt: prompt,
            onboarded: onboarded,
          }),
        })
          .then((response) => response.arrayBuffer())
          .then((arrayBuffer) => {
            handleNewAudioChunk(arrayBuffer);
            resetTranscript();
            setGenerating(false);
          })
          .catch((error) => {
            mixpanel.track("Error during call", {
              name: sellerName,
            });
            endCall();
            throw error;
          });
      }
    }
  };

  useEffect(() => {
    let timeoutId;
    if (
      !currentChunk &&
      !finalTranscript &&
      !generating &&
      !interimTranscript &&
      !aiSpeech
    ) {
      timeoutId = setTimeout(() => {
        if (
          !currentChunk &&
          !finalTranscript &&
          !generating &&
          !interimTranscript &&
          !aiSpeech
        ) {
          triggerTTS("[silence]");
        }
      }, 8000);
    } else {
      clearTimeout(timeoutId);
    }
    return () => clearTimeout(timeoutId);
  }, [currentChunk, finalTranscript, aiSpeech, generating, interimTranscript]);

  useEffect(() => {
    if (finalTranscript && !aiSpeech) {
      setTimeout(() => {
        setFinishedSpeaking(true);
      }, 1000);
      triggerTTS(finalTranscript);
    }
  }, [finalTranscript]);

  useEffect(() => {
    if (interimTranscript) {
      setFinishedSpeaking(false);
    }
  }, [interimTranscript]);

  useEffect(() => {
    return () => {
      stopSpeechRecognitionAndChunk();
    };
  }, []);

  return (
    calling && (
      <>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.93)",
            position: "absolute",
            top: 0,
            overflow: "hidden",
            width: "105vw",
            height: "100vh",
            zIndex: 18000,
            opacity: 1,
            left: 0,
            overflow: "hidden",
          }}
          className="d-flex justify-content-center"
          onClick={() => setCallStarted(true)}
        >
          <MDBRow style={{ marginTop: "0%" }}>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  color: "white",
                  fontFamily: "PlusJSBold",
                  position: "absolute",
                  marginTop: "5%",
                }}
              >
                Note: These are not real people talking, it's all fake.
              </p>
            </MDBCol>
            <MDBCol
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="d-flex justify-content-center"
              size="12"
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <img
                    src={image}
                    style={{
                      height: 90,
                      width: 90,
                      borderRadius: 300,
                      marginTop: 0,
                      boxShadow: aiSpeech
                        ? `0px 0px ${shadowRadius}px 20px #38ef7d`
                        : null,
                      objectFit: "cover",
                      alignSelf: "center",
                    }}
                  />
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      color: "white",
                      fontFamily: "PlusJSMedium",
                      fontSize: 15,
                      marginTop: -10,
                      textAlign: "center",
                    }}
                  >
                    {sellerName}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="d-flex justify-content-center"
              size="12"
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <div
                    style={{
                      height: 90,
                      width: 90,
                      borderRadius: 300,
                      marginTop: -60,
                      boxShadow: !aiSpeech
                        ? `0px 0px ${shadowRadius}px 20px #38ef7d`
                        : null,
                      objectFit: "cover",
                      alignSelf: "center",
                    }}
                  >
                    <MDBRow>
                      <MDBCol
                        className="d-flex justify-content-center"
                        size="12"
                      >
                        <p
                          style={{
                            color: "white",
                            fontSize: 16,
                            marginTop: 28,
                            fontFamily: "PlusJSExtraBold",
                          }}
                        >
                          You
                        </p>
                      </MDBCol>
                      <MDBCol
                        className="d-flex justify-content-center"
                        size="12"
                      >
                        {!aiSpeech ? (
                          <BsFillMicFill
                            style={{
                              color: "white",
                              fontSize: 15,
                              marginTop: -9,
                            }}
                          />
                        ) : (
                          <BsFillMicMuteFill
                            style={{
                              color: "white",
                              fontSize: 15,
                              marginTop: -9,
                            }}
                          />
                        )}
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      color: "white",
                      fontFamily: "PlusJSMedium",
                      fontSize: 34,
                      marginTop: 55,
                      textAlign: "center",
                    }}
                  ></p>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol
              style={{ height: 10, marginTop: -90 }}
              className="d-flex justify-content-center"
              size="12"
            >
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "white",
                }}
              >
                {renderText(text)}
              </p>
            </MDBCol>

            <MDBCol
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: -140,
              }}
              className="d-flex justify-content-center"
              size="12"
            >
              <div
                style={{
                  height: 60,
                  width: 60,
                  backgroundColor: "red",
                  borderRadius: 200,
                  marginTop: 0,
                  marginLeft: 0,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="d-flex justify-content-center"
                onClick={async () => {
                  await endCall();
                }}
              >
                <ImPhoneHangUp
                  style={{
                    color: "white",
                    marginTop: 15,
                    fontSize: 25,
                  }}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </div>
        <div style={{ color: "white" }}></div>
      </>
    )
  );
};
export default CallMobile;
