import React, { useEffect, useState, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { IoCall } from "react-icons/io5";
import { withRouter } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { firebase } from "../../firebase/config";
import { SkyLightStateless } from "react-skylight";
import Call from "../Layout/Call";
import MiniRow from "./MiniRow";
import mixpanel from "mixpanel-browser";

import SpeechRecognition from "react-speech-recognition";
import useFirebaseStorage from "../../hooks/useFirebaseStorage";

const Row = ({
  avatars,
  color,
  Category,
  user,
  setLoginVisible,
  allAvatars,
  setUpgradeVisible,
  subscribed,
}) => {
  const { uploadBlob, uploadFile } = useFirebaseStorage();

  const endCallRef = useRef();

  const [image, setImage] = useState(null);
  const [callingVisible, setCallingVisible] = useState(false);
  const [calling, setCallingHook] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [visible, setVisible] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [timer, setTimer] = useState(null);

  const [conversationClips, setConversationClips] = useState([]);

  // async function downloadAndConcatenateAudioClips(urls) {
  //     // Create an array to hold the downloaded audio data
  //     const audioDataArray = [];

  //     // Loop through each URL
  //     for (let i = 0; i < urls.length; i++) {
  //         const url = urls[i];

  //         // Download the audio data from the URL
  //         const response = await fetch(url);
  //         const audioData = await response.arrayBuffer();

  //         // Add the audio data to the array
  //         audioDataArray.push(audioData);
  //     }

  //     // Concatenate the audio data into a single ArrayBuffer
  //     const concatenatedAudioData = audioDataArray.reduce(
  //         (acc, current) =>
  //             new Uint8Array([...acc, ...new Uint8Array(current)]),
  //         new Uint8Array()
  //     ).buffer;

  //     // Convert the ArrayBuffer to a Blob
  //     const blob = new Blob([concatenatedAudioData], { type: "audio/mpeg" });

  //     // Save the Blob as a file
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "concatenated-audio.mpeg";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  // }

  const playEndSound = () => {
    endCallRef.current.play();
  };

  const setCalling = (value) => {
    if (value === true) {
      recorder.clearBlobUrl();
      setConversationClips([]);
      setCallingHook(value);
    }

    if (value === false) {
      recorder.clearBlobUrl();
      SpeechRecognition.abortListening();
      setCallingHook(value);
    }
  };

  useEffect(() => {
    if (user && firebase.auth().currentUser) {
      let uid = user.uid;

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          if (doc.data().timer) {
            setTimer(doc.data().timer);
          }
        });
    }
  }, [user]);

  useEffect(() => {
    if (timer && calling && !subscribed) {
      if (timer > 80) {
        setCalling(false);
        setUpgradeVisible(true);
      }
    }
  }, [timer, calling, subscribed]);

  useEffect(() => {
    if (calling) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
      SpeechRecognition.abortListening();
      SpeechRecognition.stopListening();
      recorder.stopRecording();
      recorder.clearBlobUrl();
    }
    // You can also set these styles on the cont

    return () => {
      SpeechRecognition.abortListening();
      SpeechRecognition.stopListening();
    };
  }, [calling]);

  const handleUpload = async (payload) => {
    return null;
  };

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={() => {
          setVisible(false);
        }}
        onCloseClicked={() => {
          setVisible(false);
        }}
        isVisible={visible}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99,
          backgroundColor: "#181616",
          opacity: 0.8,
        }}
        dialogStyles={{
          zIndex: 950,
          backgroundColor: "#111",
          padding: "10px 3px 10px 40px",
          borderRadius: "8px",
          width: "832px",
          minHeight: "60px",
          top: "45%",
          height: "460px",
          border: "0px solid #393e45",
          position: "fixed",
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
        closeButtonStyle={{
          color: "white",
          right: 30,
          position: "absolute",
          marginTop: 15,
          zIndex: 9000,
        }}
      >
        <MDBRow style={{ width: "95%" }}>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontWeight: "900",
                fontFamily: "Grifter",
                fontSize: 25,
                opacity: 1,
                lineHeight: 1.2,
                textAlign: "center",
                marginTop: 20,
                width: 400,
              }}
            >
              BANTERAI VOICES
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow
          className="miniRow"
          style={{
            overflowY: "scroll",
            height: 400,
            overflowX: "hidden",
            width: 800,
          }}
        >
          <MiniRow
            setCallingVisible={setCallingVisible}
            setCalling={setCalling}
            setSellerName={setSellerName}
            subscribed={subscribed}
            setUpgradeVisible={setUpgradeVisible}
            setPrompt={setPrompt}
            setVisible={setVisible}
            setVoiceID={setVoiceID}
            setImage={setImage}
            setLoginVisible={setLoginVisible}
            user={user}
            color="Dark"
            Category="Tech"
            avatars={allAvatars.filter((avatar) => avatar.Category === "Tech")}
          />
          <MiniRow
            setCallingVisible={setCallingVisible}
            setCalling={setCalling}
            setSellerName={setSellerName}
            setPrompt={setPrompt}
            setVisible={setVisible}
            subscribed={subscribed}
            setUpgradeVisible={setUpgradeVisible}
            setVoiceID={setVoiceID}
            setImage={setImage}
            setLoginVisible={setLoginVisible}
            user={user}
            color="Dark"
            Category="Politics"
            avatars={allAvatars.filter(
              (avatar) => avatar.Category === "Political"
            )}
            marginTop={20}
          />

          <MiniRow
            setCallingVisible={setCallingVisible}
            setCalling={setCalling}
            setVisible={setVisible}
            setPrompt={setPrompt}
            setSellerName={setSellerName}
            subscribed={subscribed}
            setUpgradeVisible={setUpgradeVisible}
            setVoiceID={setVoiceID}
            setImage={setImage}
            setLoginVisible={setLoginVisible}
            user={user}
            color="Dark"
            Category="Hollywood"
            avatars={allAvatars.filter((avatar) => avatar.Category === "Actor")}
            marginTop={20}
          />

          <MiniRow
            setCallingVisible={setCallingVisible}
            setCalling={setCalling}
            setVisible={setVisible}
            setPrompt={setPrompt}
            subscribed={subscribed}
            setSellerName={setSellerName}
            setUpgradeVisible={setUpgradeVisible}
            setVoiceID={setVoiceID}
            setImage={setImage}
            setLoginVisible={setLoginVisible}
            user={user}
            color="Dark"
            Category="Music"
            avatars={allAvatars.filter((avatar) => avatar.Category === "Music")}
            marginTop={20}
          />

          <MiniRow
            setCallingVisible={setCallingVisible}
            setCalling={setCalling}
            setSellerName={setSellerName}
            setPrompt={setPrompt}
            setVisible={setVisible}
            subscribed={subscribed}
            setUpgradeVisible={setUpgradeVisible}
            setVoiceID={setVoiceID}
            setImage={setImage}
            setLoginVisible={setLoginVisible}
            user={user}
            color="Dark"
            Category="Influencers"
            avatars={allAvatars.filter(
              (avatar) => avatar.Category === "Influencer"
            )}
            marginTop={20}
          />

          <MiniRow
            setCallingVisible={setCallingVisible}
            setCalling={setCalling}
            setVisible={setVisible}
            setPrompt={setPrompt}
            setSellerName={setSellerName}
            subscribed={subscribed}
            setUpgradeVisible={setUpgradeVisible}
            setVoiceID={setVoiceID}
            setImage={setImage}
            setLoginVisible={setLoginVisible}
            user={user}
            color="Dark"
            Category="Anime"
            avatars={allAvatars.filter(
              (avatar) =>
                avatar.Category === "Anime" || avatar.Category === "Video game"
            )}
            marginTop={20}
          />

          <MiniRow
            setCallingVisible={setCallingVisible}
            setCalling={setCalling}
            setVisible={setVisible}
            setSellerName={setSellerName}
            subscribed={subscribed}
            setPrompt={setPrompt}
            setUpgradeVisible={setUpgradeVisible}
            setVoiceID={setVoiceID}
            setImage={setImage}
            setLoginVisible={setLoginVisible}
            user={user}
            color="Dark"
            Category="TV & Movies"
            avatars={allAvatars.filter(
              (avatar) => avatar.Category === "TV/Movies"
            )}
            marginTop={20}
          />
        </MDBRow>
      </SkyLightStateless>
      <audio
        volume={1}
        id="Jibril"
        ref={endCallRef}
        src="https://res.cloudinary.com/dvgkc6gso/video/upload/v1679690772/Y2Mate.is_-_FaceTime_End_Call_Sound_Tiktok_Orignal_-ZOK0F8LhYxg-128k-1655341892542_lubme7.mp3"
      />
      {callingVisible && calling && user && (
        <Call
          image={image}
          isMobile={false}
          visible={callingVisible}
          calling={calling}
          setCalling={setCalling}
          prompt={prompt}
          sellerName={sellerName}
          voiceID={voiceID}
          uid={user.uid}
          playEndSound={playEndSound}
          recorder={recorder}
          handleUpload={handleUpload}
          conversationClips={conversationClips}
        />
      )}
      <MDBRow
        style={{
          marginTop: 0,
          overflowY: "hidden",
          backgroundColor: color === "Dark" ? "#111" : "#252525",
          paddingTop: 24,
          paddingBottom: 20,
          overflowX: "hidden",
        }}
        className="backy60"
      >
        <MDBCol className="d-flex justify-content-between" size="12">
          {/* <div onClick={() => setVisible(true)}>
            <p
              style={{
                marginRight: 40,
                fontFamily: "PlusJSMedium",
                color: "white",
                cursor: "pointer",
              }}
            >
              See all avatars
              <BiChevronRight style={{ marginLeft: 8, marginTop: -2 }} />
            </p>
          </div> */}
        </MDBCol>

        <MDBRow
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            width: 1100,
            alignSelf: "center",
            margin: "20px auto",
          }}
        >
          {avatars.map((seller) => (
            <MDBCol key={seller.name} size="3" style={{ paddingBottom: 50 }}>
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <img
                    onClick={() => {
                      if (!firebase.auth().currentUser) {
                        mixpanel.track("Tried to call but not logged in", {
                          name: seller.name,
                        });
                        setLoginVisible(true);
                      } else {
                        if (
                          seller.Free !== true ||
                          (!subscribed && timer > 80)
                        ) {
                          mixpanel.track("Tried to call but paywall", {
                            name: seller.name,
                          });
                          setUpgradeVisible(true);
                        } else {
                          mixpanel.track("Called", {
                            name: seller.name,
                          });
                          setCallingVisible(true);
                          setCalling(true);
                          setSellerName(seller.name);
                          setVoiceID(seller.voiceID);
                          setPrompt(seller.prompt);
                          setImage(seller.image);
                          window.scrollTo({
                            top: 0,
                          });
                        }
                      }
                    }}
                    src={seller.image}
                    style={{
                      width: 200,
                      height: 200,
                      objectFit: "cover",
                      borderRadius: 200,
                      cursor: "pointer",
                      border:
                        seller.Free === true
                          ? "6px solid #77FFBA"
                          : "6px solid grey",
                      opacity: seller.Free === true ? 1 : 0.5,
                    }}
                  />
                </MDBCol>
                <MDBCol
                  style={{
                    overflowY: "hidden",
                    zIndex: 0,
                    marginTop: -30,
                  }}
                  className="d-flex justify-content-center"
                  size="12"
                >
                  <div
                    style={{
                      fontSize: 19,
                      fontFamily: "PlusJSBold",
                      textAlign: "center",
                      alignSelf: "center",
                      height: 60,
                      width: 60,
                      backgroundColor:
                        seller.Free === true ? "#4cda63" : "grey",
                      borderRadius: 200,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (!firebase.auth().currentUser) {
                        mixpanel.track("Tried to call but not logged in", {
                          name: seller.name,
                        });
                        setLoginVisible(true);
                      } else {
                        if (
                          seller.Free !== true ||
                          (!subscribed && timer > 80)
                        ) {
                          mixpanel.track("Tried to call but paywall", {
                            name: seller.name,
                          });
                          setUpgradeVisible(true);
                        } else {
                          mixpanel.track("Called", {
                            name: seller.name,
                          });
                          setCallingVisible(true);
                          setCalling(true);
                          setSellerName(seller.name);
                          setVoiceID(seller.voiceID);
                          setPrompt(seller.prompt);
                          setImage(seller.image);
                          window.scrollTo({
                            top: 0,
                          });
                        }
                      }
                    }}
                  >
                    {seller.Free === true ? (
                      <IoCall
                        style={{
                          marginTop: 18,
                          fontSize: 25,
                          color: "white",
                        }}
                      />
                    ) : (
                      <FaLock
                        style={{
                          marginTop: 18,
                          fontSize: 25,
                        }}
                      />
                    )}
                  </div>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontSize: 19,
                      marginTop: 20,
                      fontFamily: "PlusJSBold",
                      color: "white",
                    }}
                  >
                    AI {seller.name[0].toUpperCase() + seller.name.substring(1)}
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontSize: 18,
                      marginTop: -9,
                      fontFamily: "PlusJSBold",
                      color: "white",
                      opacity: 0.7,
                    }}
                  >
                    {seller.Subtitle}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          ))}
        </MDBRow>
        <MDBRow
          className="d-flex justify-content-center"
          style={{ width: "100%", textAlign: "center" }}
        >
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              onClick={() => {
                if (!subscribed) {
                  setUpgradeVisible(true);
                } else {
                  setVisible(true);
                }
              }}
              style={{
                margin: "20px auto",
                cursor: "pointer",
                height: 50,
                width: 700,
                border: "1px solid white",
                alignSelf: "center",
                borderRadius: 100,
                opacity: 0.7,
              }}
            >
              <p
                style={{
                  color: "white",
                  fontFamily: "PlusJSSemiBold",
                  marginTop: 12,
                }}
              >
                Get unlimited access to 50+ voices
              </p>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBRow>
    </>
  );
};

export default withRouter(Row);
