import React from "react";
import "../index.css";
import { MDBRow, MDBCol } from "mdbreact";
import Footer from "../Layout/Footer";
import MenuDesktop from "../Layout/MenuDesktop";

const Terms = ({ user }) => {
  return (
    <>
      <MDBCol
        style={{
          width: "105vw",
          overflowX: "hidden",
          paddingBottom: "20vh",
          backgroundColor: "#101340",

          color: "white",
        }}
        size="12"
      >
        <MenuDesktop user={user} />
        <MDBRow style={{ marginTop: 120 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 35,
                fontWeight: "600",
                marginLeft: 120,
              }}
            >
              Terms Of Use
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                fontWeight: "400",
                marginLeft: 120,
                marginTop: -15,
                width: "60%",
                lineHeight: 1.7,
              }}
            >
              Agreement to Terms These terms and conditions stipulate the rules
              and regulations for the use of ShookLLC's Website. We assure you
              that we do not record your voice or sell your personal
              information. By accessing this website, we presume you accept
              these terms and conditions. Please refrain from using our services
              if you disagree with any of the terms and conditions laid out on
              this page. The following terminology applies to these Terms and
              Conditions, Privacy Statement, Disclaimer Notice, and all
              Agreements: "Client", "You", and "Your" refers to you, the person
              logging onto this website and complying with the Company’s terms
              and conditions. "The Company", "Ourselves", "We", "Our", and "Us",
              refers to our Company. "Party", "Parties", or "Us", refers to both
              the Client and ourselves. All terms refer to the offer,
              acceptance, and consideration of payment necessary to undertake
              the process of our assistance to the Client in the most suitable
              manner for the specific purpose of fulfilling the Client’s needs
              regarding the provision of the Company’s stated services, in
              accordance with and subject to, prevailing law of the United
              Kingdom. Any use of the aforementioned terminology or other words
              in the singular, plural, capitalization and/or he/she or they, are
              considered interchangeable and hence referring to the same.
              Cookies We employ the use of cookies. By accessing our website,
              you agree to use cookies in alignment with the ShookLLC's Privacy
              Policy. Most interactive websites use cookies to let us retrieve
              the user’s details for each visit. Cookies are used by our website
              to enable the functionality of certain areas, making it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies. License Unless otherwise stated,
              ShookLLC and/or its licensors own the intellectual property rights
              for all material on https://BOLLYWOODAI.link. All intellectual
              property rights are reserved. You may access this from
              https://www.useBOLLYWOODAI.com for your own personal use subjected
              to restrictions set in these terms and conditions. You must not:
              Republish material from https://BOLLYWOODAI.link Sell, rent or
              sub-license material from https://BOLLYWOODAI.link Reproduce,
              duplicate or copy material from https://BOLLYWOODAI.link
              Redistribute content from https://BOLLYWOODAI.link This Agreement
              shall begin on the date hereof. Parts of this website offer an
              opportunity for users to post and exchange opinions and
              information in certain areas of the website. ShookLLC does not
              filter, edit, publish or review Comments prior to their presence
              on the website. Comments do not reflect the views and opinions of
              ShookLLC, its agents and/or affiliates. Comments reflect the views
              and opinions of the person who post their views and opinions. To
              the extent allowed by applicable laws, ShookLLC shall not be
              liable for the Comments or for any liability, damages or expenses
              caused and/or suffered as a result of any use of and/or posting of
              and/or appearance of the Comments on this website. ShookLLC
              reserves the right to monitor all Comments and to remove any
              Comments which can be considered inappropriate, offensive or
              causes a breach of these Terms and Conditions. You warrant and
              represent that: You are entitled to post the Comments on our
              website and have all necessary licenses and consents to do so; The
              Comments do not invade any intellectual property right, including
              without limitation copyright, patent or trademark of any third
              party; The Comments do not contain any defamatory, libelous,
              offensive, indecent or otherwise unlawful material which is an
              invasion of privacy; The Comments will not be used to solicit or
              promote business or custom or present commercial
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <Footer />
    </>
  );
};

export default Terms;
