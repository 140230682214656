import React, { useState, useEffect, useRef } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { withRouter } from "react-router-dom";
import "./index.css";
import { IoCall } from "react-icons/io5";
import mixpanel from "mixpanel-browser";
import { firebase } from "../../firebase/config";
import MobileFooter from "../Layout/mobileFooter";
import CallMobile from "../Layout/CallMobile";
import { FaLock } from "react-icons/fa";
import MobileTopMenu from "../Layout/mobileTopMenu";
import { Howl } from "howler";
import RedirectDesktop from "./RedirectDesktop";

const WelcomeMobile = ({ user }) => {
  const [avatars, setAvatars] = useState([]);
  const [Category, setCategory] = useState("All");
  const [image, setImage] = useState(null);
  const [callingVisible, setCallingVisible] = useState(false);
  const [calling, setCalling] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [voiceID, setVoiceID] = useState("");
  const [prompt, setPrompt] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [micAccessGranted, setMicAccessGranted] = useState(false);
  const [callingSound, setCallingSound] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  function getMicrophoneAccess() {
    return navigator.mediaDevices.getUserMedia({ audio: true });
  }

  useEffect(() => {
    if (calling) {
      const sound = new Howl({
        src: [
          "https://res.cloudinary.com/dvgkc6gso/video/upload/v1679687553/Y2Mate.is_-_iPhone_Ringtone_Trap_Remix-HRtC2sDiKqM-160k-1657007098578_nk6dpr.mp3",
        ],
        loop: true,
        volume: 1,
      });

      sound.play();
      setCallingSound(sound);
    }
  }, [calling]);

  useEffect(() => {
    if (!calling && callingSound) {
      if (callingSound.playing()) {
        callingSound.stop();
      }
    }
  }, [calling, callingSound]);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      firebase
        .firestore()
        .collection("customers")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          let initActive = [];
          querySnapshot.forEach((doc) => {
            initActive.push(doc.data());
          });
          if (initActive.length > 0) {
            setSubscribed(true);
          }
        });
    }
  }, []);

  const playEndSound = () => {
    const howl = new Howl({
      src: [
        "https://res.cloudinary.com/dvgkc6gso/video/upload/v1679690772/Y2Mate.is_-_FaceTime_End_Call_Sound_Tiktok_Orignal_-ZOK0F8LhYxg-128k-1655341892542_lubme7.mp3",
      ],
      format: ["mp3"],
      onload: () => {
        howl.play();
      },
      onloaderror: () => {
        console.error("Error loading audio");
      },
    });
  };

  useEffect(() => {
    if (calling) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
      document.documentElement.style.zoom = "1"; // Set the zoom level to 100%
    } else {
      document.body.style.overflow = "scroll";
    }
    // You can also set these styles on the cont
  }, [calling]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("avatars")
      .get()
      .then((querySnapshot) => {
        let empty = [];
        querySnapshot.forEach((doc) => {
          empty.push(doc.data());
        });
        setAvatars(empty);
      });
  }, []);

  return (
    <>
      <RedirectDesktop isOpen={isOpen} setIsOpen={setIsOpen} />

      {callingVisible && calling && user && micAccessGranted && (
        <CallMobile
          image={image}
          isMobile={false}
          visible={callingVisible}
          calling={calling}
          setCalling={setCalling}
          sellerName={sellerName}
          voiceID={voiceID}
          callingSound={callingSound}
          setCallingSound={setCallingSound}
          prompt={prompt}
          uid={user.uid}
          playEndSound={playEndSound}
        />
      )}
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#2b2b2b",
          color: "white",
        }}
      >
        <MDBRow>
          <MDBCol size="12">
            <MobileTopMenu user={user} subscribed={subscribed} />
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 80 }}>
          <MDBCol
            style={{
              marginLeft: 10,
              paddingLeft: 5,
              paddingBottom: "10vh",
              paddingRight: 10,
            }}
            size="12"
          >
            <MDBRow
              className="d-flex justify-content-between"
              style={{ marginTop: 40 }}
            >
              <div>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSSemiBold",
                      fontSize: 20,
                      fontWeight: "600",
                      opacity: 0.8,
                      color: "white",
                      marginLeft: 30,
                    }}
                  >
                    Explore all avatars
                  </p>
                </MDBCol>
              </div>
            </MDBRow>

            <MDBRow>
              <MDBCol style={{ zIndex: 800, width: "80vw" }} size="8">
                <select
                  style={{
                    height: 40,
                    width: "85vw",
                    marginLeft: 30,
                    backgroundColor: "#2b2b2b",
                    border: "1px solid white",
                    color: "white",
                    fontFamily: "PlusJSSemiBold",
                    paddingLeft: 15,
                    paddingRight: 20,
                    opacity: 0.75,
                  }}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  value={Category}
                >
                  <option value="All">All Avatars</option>
                  <option value="Tech">Tech</option>
                  <option value="Political">Politicians</option>
                  <option value="Influencer">Influencers</option>
                  <option value="Actor">Actors</option>
                  <option value="Music">Musician</option>
                  <option value="Anime">Anime</option>
                  <option value="TV/Movies">TV/Movies</option>
                  <option value="Sports">Sports</option>
                </select>
              </MDBCol>
            </MDBRow>

            <MDBRow
              style={{
                marginTop: 5,
                marginLeft: 15,
                width: "95vw",
                marginTop: 40,
              }}
            >
              {avatars.length > 1
                ? avatars
                    .filter((avatar) => {
                      if (Category === "All") {
                        return avatar;
                      } else if (Category === "Anime") {
                        return (
                          avatar.Category === "Anime" ||
                          avatar.Category === "Video Games"
                        );
                      } else {
                        return avatar.Category === Category;
                      }
                    })
                    .map((seller) => {
                      return (
                        <MDBCol
                          key={seller.name}
                          style={{ marginBottom: 20 }}
                          size="6"
                        >
                          <MDBRow>
                            <MDBCol
                              className="d-flex justify-content-center"
                              size="12"
                            >
                              <img
                                src={seller.image}
                                onClick={() => {
                                  if (!firebase.auth().currentUser) {
                                    window.location.href = "/sign-in";
                                  } else {
                                    if (seller.Free !== true && !subscribed) {
                                      window.location.href = "/upgrade";
                                    } else {
                                      setIsOpen(true);
                                    }
                                  }
                                }}
                                style={{
                                  width: 140,
                                  height: 140,
                                  objectFit: "cover",
                                  borderRadius: 200,
                                  border:
                                    seller.Free === true || subscribed
                                      ? "4px solid #77FFBA"
                                      : "4px solid grey",
                                  opacity:
                                    seller.Free === true || subscribed
                                      ? 1
                                      : 0.5,
                                }}
                              />
                              <div
                                style={{
                                  fontSize: 19,
                                  marginTop: 0,
                                  fontFamily: "PlusJSBold",
                                  position: "absolute",
                                  top: 115,
                                  textAlign: "center",
                                  alignSelf: "center",
                                  height: 40,
                                  width: 40,
                                  backgroundColor:
                                    seller.Free === true || subscribed
                                      ? "#4cda63"
                                      : "grey",

                                  borderRadius: 200,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (!firebase.auth().currentUser) {
                                    window.location.href = "/sign-in";
                                  } else {
                                    if (seller.Free !== true && !subscribed) {
                                      window.location.href = "/upgrade";
                                    } else {
                                      setIsOpen(true);
                                    }
                                  }
                                }}
                              >
                                {seller.Free === true || subscribed ? (
                                  <IoCall
                                    style={{ marginTop: 11, fontSize: 16 }}
                                  />
                                ) : (
                                  <FaLock
                                    style={{ marginTop: 11, fontSize: 16 }}
                                  />
                                )}
                              </div>
                            </MDBCol>
                            <MDBCol
                              className="d-flex justify-content-center"
                              size="12"
                            >
                              <p
                                style={{
                                  fontSize: 15,
                                  marginTop: 20,
                                  fontFamily: "PlusJSBold",
                                }}
                              >
                                {seller.name[0].toUpperCase() +
                                  seller.name.substring(1)}
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      );
                    })
                : Array.from({ length: 50 }, (_, i) => i).map((seller) => {
                    return (
                      <MDBCol
                        key={seller.name}
                        style={{ marginBottom: 20 }}
                        size="6"
                      >
                        <MDBRow>
                          <MDBCol
                            className="d-flex justify-content-center"
                            size="12"
                          >
                            <div
                              style={{
                                width: 150,
                                height: 150,
                                borderRadius: 100,
                                backgroundColor: "grey",
                              }}
                            />
                          </MDBCol>
                          <MDBCol
                            className="d-flex justify-content-center"
                            style={{ marginTop: 10 }}
                            size="12"
                          >
                            <div
                              style={{
                                width: 160,
                                borderRadius: 80,
                                height: 20,
                                backgroundColor: "grey",
                              }}
                            ></div>
                          </MDBCol>

                          <MDBCol
                            className="d-flex justify-content-center"
                            style={{ marginTop: 10 }}
                            size="12"
                          >
                            <div
                              style={{
                                width: 80,
                                borderRadius: 80,
                                height: 20,
                                backgroundColor: "grey",
                              }}
                            ></div>
                          </MDBCol>
                          <MDBCol
                            className="d-flex justify-content-center"
                            style={{ marginTop: 10 }}
                            size="12"
                          >
                            <div
                              style={{
                                width: 80,
                                borderRadius: 80,
                                height: 20,
                                backgroundColor: "grey",
                              }}
                            ></div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    );
                  })}
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <MobileFooter />
      </div>
    </>
  );
};

export default withRouter(WelcomeMobile);
