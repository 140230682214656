import React from "react";
import "../index.css";
import Footer from "../Layout/Footer";
import { MDBCol, MDBRow } from "mdbreact";
import MenuDesktop from "../Layout/MenuDesktop";

const Privacy = ({ user }) => {
  return (
    <>
      <MDBCol
        style={{
          width: "105vw",
          overflowX: "hidden",
          paddingBottom: "20vh",
          backgroundColor: "#101340",

          color: "white",
        }}
        size="12"
      >
        <MenuDesktop user={user} />
        <MDBRow style={{ marginTop: 120 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 35,
                fontWeight: "600",
                marginLeft: 120,
              }}
            >
              Privacy Policy
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 19,
                fontWeight: "400",
                marginLeft: 120,
                marginTop: -15,
                width: "60%",
                lineHeight: 1.7,
              }}
            >
              We at ShookLLC (DBA BollywoodAI) are dedicated to safeguarding
              your privacy and ensuring the protection of your personal data.
              This Privacy Policy provides insight into how we acquire, utilize,
              and secure your personal data when you interact with our
              BollywoodAI app. Information We Gather During your use of the
              BollywoodAI app, we might gather personal information such as your
              email address, city, region, country, mobile service provider, iOS
              version, manufacturer, device model, operating system, wifi usage
              (True or False), and timezone. This is done to offer you the best
              possible user experience. Moreover, as you converse with our AI
              bots, they can recall previous discussions and the details you
              have provided to create a more personalized interaction.
              Utilization of Your Information We might use your email address to
              occasionally send newsletters or promotional offers relevant to
              the BollywoodAI app. We assure you that we will never share your
              email address or any other personal information with any third
              parties unless mandated by law or explicitly approved by you. We
              do not personally review your conversations or data, nor do we
              disclose it to any human. Although our AI bots might use the
              information shared by you to improve your interaction, we do not
              use or share this information for any other reason. We employ
              standard industry security measures to shield your personal data
              from unauthorized access, disclosure, or misuse. However, please
              note that no data transmission over the internet can be fully
              secure, so we cannot promise absolute security of your personal
              data. Your Choices You can choose to unsubscribe from our
              promotional emails at any point by clicking on the "unsubscribe"
              link located at the end of the email. You can also ask us to erase
              your personal data from our records by reaching out to us at
              help@bollywoodai.app. Policy Changes We might periodically update
              this Privacy Policy. Therefore, we urge you to review this policy
              from time to time for any modifications. Your ongoing use of the
              BollywoodAI app following any changes to this policy signifies
              your agreement with the updated terms. Contact Us If you have any
              queries or concerns about this Privacy Policy or our data
              practices, feel free to contact us at help@bollywoodai.app. Thank
              you for choosing BollywoodAI!
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <Footer />
    </>
  );
};

export default Privacy;
