import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { firebase } from "../../firebase/config";
import "../index.css";
import { NavLink } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import { useUser } from "../../UserContext";

const SignInMobile = () => {
  const [signUp, setSignUp] = useState(true);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user, setUser } = useUser();

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmitEmail = (Email, Password) => {
    if (!Email || !Password) {
      return setError("Missing email or password!");
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(Email, Password)
        .then((userCredential) => {
          const uid = userCredential.user.uid;
          mixpanel.identify(uid);
          const searchParams = new URLSearchParams(window.location.search);
          const source = searchParams.get("source");
          if (source) {
            mixpanel.track("Signed Up", {
              Source: source,
              signUpMethod: "email",
            });
          } else {
            mixpanel.track("Signed Up", {
              Source: "referral",
              signUpMethod: "email",
            });
          }

          return firebase.firestore().collection("users").doc(uid).set({
            email: userCredential.user.email,
            uid: uid,
          });
        })
        .then((userData) => {
          setUser(userData);
          window.location.href = "/";
          setError(null);
        })
        .catch((error) => {
          if (error.message === "auth/popup-closed-by-user") {
            return null;
          } else {
            setError(error.message);
            throw error;
          }
        });
    }
  };

  const onSignInEmail = (Email, Password) => {
    if (!Email || !Password) {
      return setError("Missing email or password");
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(Email, Password)
        .then(() => {
          const uid = firebase.auth().currentUser.uid;
          mixpanel.identify(uid);
        })
        .then(() => {
          window.location.href = "/";

          setError(null);
        })
        .catch((error) => {
          if (error.message === "auth/popup-closed-by-user") {
            return null;
          } else {
            setError(error.message);
            throw error;
          }
        });
    }
  };

  const onSubmitGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((socialAuthUser) => {
        const uid = socialAuthUser.user.uid;
        mixpanel.identify(uid);

        return firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              const searchParams = new URLSearchParams(window.location.search);
              const source = searchParams.get("source");
              if (source) {
                mixpanel.track("Signed Up", {
                  Source: source,
                  signUpMethod: "google",
                });
              } else {
                mixpanel.track("Signed Up", {
                  Source: "referral",
                  signUpMethod: "google",
                });
              }
              return firebase.firestore().collection("users").doc(uid).set({
                email: socialAuthUser.user.email,
                uid: uid,
              });
            } else {
              return doc.data();
            }
          });
      })
      .then((userData) => {
        setUser(userData); // Update the user state with the fetched or created user data
        window.location.href = "/";
        setError(null);
      })
      .catch((error) => {
        if (error.message === "auth/popup-closed-by-user") {
          return null;
        } else {
          setError(error.message);
          throw error;
        }
      });
  };
  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "#101340",

        height: "110vh",
      }}
      className="backy60"
    >
      <MDBRow>
        <MDBCol className="d-flex justify-content-center" size="12">
          <NavLink to="/" exact>
            <p
              style={{
                color: "white",
                fontWeight: "900",
                fontFamily: "TahomaBold",
                fontSize: 25,
                opacity: 1,
                lineHeight: 1.2,
                textAlign: "center",
                marginTop: 40,
                width: 400,
              }}
            >
              BollywoodAI
            </p>
          </NavLink>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              color: "white",
              fontWeight: "900",
              fontFamily: "PlusJSMedium",
              fontSize: 20,
              opacity: 1,
              lineHeight: 1.2,
              textAlign: "center",
              marginTop: 10,
              width: 400,
            }}
          >
            {signUp ? "Create your Account" : "Log In to Your Account"}
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              color: "white",
              fontFamily: "PlusJSMedium",
              fontSize: 16,
              opacity: 0.8,
              lineHeight: 1.2,
              textAlign: "center",
              marginTop: 2,
              width: 400,
              cursor: "pointer",
            }}
            onClick={() => {
              setSignUp(!signUp);
            }}
          >
            {signUp
              ? "or sign into your account"
              : "Or sign up for a free account"}
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <div
            style={{
              height: 40,
              width: 270,
              backgroundColor: "white",
              marginTop: 5,
              border: "1px solid #dadce0",
              borderRadius: 4,
              cursor: "pointer",
            }}
            className="d-flex justify-content-center"
            onClick={() => onSubmitGoogle()}
          >
            <p
              style={{
                fontFamily: "PlusJSBold",
                color: "#3c4043",
                fontSize: 14,
                marginTop: 8,
              }}
              className="d-inline"
            >
              <img
                className="d-inline"
                style={{
                  position: "absolute",
                  marginLeft: signUp ? -58 : -60,
                  fontSize: 13,
                  height: 20,
                  marginTop: 2,
                }}
                src={require("./Google.png")}
              />{" "}
              {signUp ? "Sign up with Google" : "Sign in with Google"}
            </p>
          </div>
        </MDBCol>
        {!signUp && (
          <>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  color: "white",
                  fontFamily: "PlusJSBold",
                  fontSize: 15,
                  opacity: 1,
                  lineHeight: 1.2,
                  textAlign: "center",
                  marginTop: 20,
                  width: 400,
                  cursor: "pointer",
                }}
              >
                Or enter your..
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  color: "white",
                  fontFamily: "PlusJSMedium",
                  marginLeft: "6%",
                  fontSize: 12,
                  marginTop: -3,
                }}
              >
                Email Address
              </p>
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <input
                style={{
                  height: 45,
                  width: "87%",
                  marginLeft: 0,
                  border: "1px solid #696969	",
                  borderRadius: 4,
                  display: "inline-block",
                  color: "white",
                  marginTop: -9,
                  backgroundColor: "#2b2b2b",
                  fontFamily: "PlusJSSemiBold",
                  paddingLeft: 17,
                  fontSize: 13,
                }}
                value={Email}
                name="Email"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email Address"
              />
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  color: "white",
                  fontFamily: "PlusJSMedium",
                  marginLeft: "6%",

                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Password
              </p>
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <input
                type="password"
                style={{
                  height: 45,
                  width: "87%",
                  marginLeft: 0,
                  border: "1px solid #696969	",
                  borderRadius: 4,
                  display: "inline-block",
                  color: "white",
                  marginTop: -9,
                  backgroundColor: "#2b2b2b",
                  fontFamily: "PlusJSSemiBold",
                  paddingLeft: 17,
                  fontSize: 13,
                }}
                name="Password"
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Password"
              />
            </MDBCol>
            <MDBCol className="d-flex justify-content-between" size="12">
              <MDBRow style={{ marginTop: 12, marginLeft: "4%" }}>
                <MDBCol size="2">
                  <input
                    type="checkbox"
                    style={{
                      backgroundColor: "transparent",
                      marginTop: 8.7,
                      color: "black",
                    }}
                  />
                </MDBCol>
                <MDBCol size="4">
                  <p
                    style={{
                      color: "white",
                      marginLeft: 0,
                      fontFamily: "PlusJSRegular",
                      width: 100,
                      marginTop: 6.6,
                      fontSize: 13,
                    }}
                  >
                    Remember Me
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ marginTop: 9, marginRight: "7.5%" }}>
                <p
                  style={{
                    marginRight: "13%",
                    fontFamily: "PlusJSBold",
                    color: "white",
                    fontSize: 13,
                    marginTop: 4.7,
                    width: 140,
                    opacity: 0.8,
                  }}
                >
                  Forgot Password?
                </p>
              </MDBRow>
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <div
                style={{
                  height: 45,
                  width: "87%",
                  marginLeft: 0,
                  borderRadius: 4,
                  display: "inline-block",
                  color: "white",
                  marginTop: 0,
                  backgroundColor: "white",
                  fontFamily: "PlusJSSemiBold",
                  paddingLeft: 17,
                  fontSize: 13,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (signUp) {
                    onSubmitEmail(Email, Password);
                  } else {
                    onSignInEmail(Email, Password);
                  }
                }}
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    color: "black",
                    marginTop: 12,
                    fontFamily: "PlusJSBold",
                    fontSize: 14,
                  }}
                >
                  {signUp ? "Sign Up" : "Log In"}
                </p>
              </div>
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  color: "#ffaed7",
                  textAlign: "center",
                  width: 400,
                  marginTop: 20,
                }}
              >
                {error}
              </p>
            </MDBCol>
          </>
        )}
      </MDBRow>
    </div>
  );
};

export default SignInMobile;
