import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";

import { firebase } from "../../firebase/config";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "./index.css";
import createSpeechServicesPonyfill from "web-speech-cognitive-services";
import { MDBRow, MDBCol } from "mdbreact";
import { ImPhoneHangUp } from "react-icons/im";
import { v4 as uuidv4 } from "uuid";
import { useStopwatch } from "react-timer-hook";
import fetch from "isomorphic-fetch";
import { BsFillMicMuteFill } from "react-icons/bs";
import { BsFillMicFill } from "react-icons/bs";
import { MdRecordVoiceOver, MdVoiceOverOff } from "react-icons/md";
import { GiTrumpet } from "react-icons/gi";
import mixpanel from "mixpanel-browser";
import concatenateAudioFiles from "../../utils/transloadit";

const prod = "https://us-central1-rogueai.cloudfunctions.net/widgets/";
const dev = "http://localhost:8000/";

const SUBSCRIPTION_KEY = "4c0a687d47ad4f0daf3c910c0f5d81c7";
const REGION = "eastus";

const { SpeechRecognition: AzureSpeechRecognition } =
  createSpeechServicesPonyfill({
    credentials: {
      region: REGION,
      subscriptionKey: SUBSCRIPTION_KEY,
    },
  });
SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
const recognition = SpeechRecognition.getRecognition();

const Call = ({
  image,
  sellerName,
  calling,
  setCalling,
  uid,
  voiceID,
  prompt,
  playEndSound,
  recorder,
  handleUpload,
  conversationClips,
}) => {
  const {
    resetTranscript,
    browserSupportsSpeechRecognition,
    interimTranscript,
    finalTranscript,
    isMicrophoneAvailable,
    listening,
  } = useSpeechRecognition();

  const { seconds, minutes, start, reset } = useStopwatch({
    autoStart: false,
  });

  const [conversationId, setConversationId] = useState("");
  const [audioChunks, setAudioChunks] = useState([]);
  const [finishedSpeaking, setFinishedSpeaking] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  // const [aiChunks, setAiChunks] = useState([]);
  // const [aiFinished, setAiFinished] = useState(false);

  useEffect(() => {
    if (recorder.mediaBlobUrl && conversationId) {
      const payload = {
        conversationId: conversationId,
        audioSrc: recorder.mediaBlobUrl,
        type: "user",
        time: `${minutes}-${seconds}`,
      };

      handleUpload(payload).then(() => {
        recorder.clearBlobUrl();
      });
    }
  }, [recorder.mediaBlobUrl]);

  const backgroundRef = useRef();

  const [generating, setGenerating] = useState(false);
  const [text, setText] = useState("Calling...");
  const [audioData, setAudioData] = useState([]);
  const [currentChunk, setCurrentChunk] = useState(null);
  const [callStarted, setCallStarted] = useState(false);

  const [gainNode, setGainNode] = useState(null);
  const [onboarded, setOnboarded] = useState(null);

  const [aiSpeech, setAISpeech] = useState(false);
  const [shadowRadius, setShadowRadius] = useState(20);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    if (conversationClips.length > 0 && seconds === 25) {
      const getClip = async () => {
        const url = await concatenateAudioFiles(conversationClips);
        setAudioUrl(url);
      };
      getClip();
    }
  }, [conversationClips, seconds]);

  // Send event to mixpanel
  async function endCall() {
    playEndSound();
    if (gainNode) {
      gainNode.gain.value = 0;
    }
    recorder.clearBlobUrl();
    setCalling(false);
    setAudioChunks([]);
    setAudioData(null);
    setCurrentChunk(null);
    setConversationId("");
    stopSpeechRecognitionAndChunk();
  }

  function stopListening() {
    // SpeechRecognition.abortListening();
    recorder.stopRecording();

    recognition.abort();
    // setAISpeech(false);
  }

  async function startListeningSpeech() {
    try {
      await SpeechRecognition.startListening({
        continuous: true,
        language: "en-US",
      });
    } catch (error) {
      console.error(error);

      if (listening) {
        stopListening();
        recorder.stopRecording();
      }
    }
  }

  function stopSpeechRecognitionAndChunk() {
    resetTranscript();
    SpeechRecognition.stopListening();
    SpeechRecognition.abortListening();
    recorder.stopRecording();
    if (currentChunk) {
      currentChunk.stop();
    }
  }

  function startSpeechRecognitionIfSupported() {
    const hasGivenMicrophonePermissionBefore =
      localStorage.getItem("hasGivenMicrophonePermissionBefore") === "true";

    if (
      browserSupportsSpeechRecognition &&
      !hasGivenMicrophonePermissionBefore
    ) {
      getMicrophoneAccess()
        .then(() => {
          localStorage.setItem("hasGivenMicrophonePermissionBefore", "true");
        })
        .catch((error) => {
          window.location.reload();
        });
    }
  }

  function getMicrophoneAccess() {
    return navigator.mediaDevices.getUserMedia({ audio: true });
  }

  const playAudio = (chunk, calling) => {
    if (calling && chunk && chunk.byteLength > 0) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const source = audioContext.createBufferSource();

      audioContext.decodeAudioData(chunk, function (buffer) {
        source.buffer = buffer;
        setAISpeech(true);
        // setAiFinished(false);

        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;

        const gainNode = audioContext.createGain();
        source.connect(gainNode);
        gainNode.connect(audioContext.destination);

        // Create low-pass filter
        const filter = audioContext.createBiquadFilter();
        filter.type = "lowpass";
        filter.frequency.setValueAtTime(2000, audioContext.currentTime);
        gainNode.connect(filter);
        filter.connect(audioContext.destination);

        setGainNode(gainNode);
        source.start(0);
        source.onended = () => {
          setCurrentChunk(null);
          setFinishedSpeaking(false);
          setAISpeech(false);
          // setAiFinished(true);
          if (finalTranscript) {
            resetTranscript();
          }
        };
      });
      setCurrentChunk(source);
    } else {
      return null;
    }
  };

  const handleNewAudioChunk = (chunk) => {
    setAudioChunks([...audioChunks, chunk]);
  };

  const playNextAudio = (audioContext) => {
    if (audioData.length === 0) {
      return;
    }
    const source = audioContext.createBufferSource();
    source.buffer = audioData[0];
    source.connect(audioContext.destination);
    setAISpeech(GiTrumpet);
    source.start(0);
    source.onended = () => {
      setAudioData((prevAudioData) => prevAudioData.slice(1));
      playNextAudio(audioContext);
      setFinishedSpeaking(false);
      setAISpeech(false);
    };
  };

  const renderText = (text) => {
    if (text === "Calling...") {
      return "Calling...";
    } else {
      return (
        <>
          {" "}
          <span>{("0" + minutes).slice(-2)}</span>:
          <span>{("0" + seconds).slice(-2)}</span>
        </>
      );
    }
  };

  const triggerTTS = async (text) => {
    try {
      if (!aiSpeech && conversationId && calling) {
        setGenerating(true);

        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(prod + "webResponse", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: text,
            id: conversationId,
            voiceID: voiceID,
            voiceName: sellerName,
            prompt: prompt,
            onboarded: onboarded,
          }),
        });

        if (!response.ok) {
          throw new Error("Error during call");
        }

        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: "audio/mpeg" });

        const payload = {
          conversationId: conversationId,
          audioSrc: blob,
          type: "ai",
          time: `${minutes}-${seconds}`,
        };

        handleUpload(payload).then(() => {
          recorder.clearBlobUrl();
        });
        // setAiChunks([arrayBuffer]);
        handleNewAudioChunk(arrayBuffer);

        if (finalTranscript) {
          resetTranscript();
        }
        // stopListening();
        setGenerating(false);
      }
    } catch (error) {
      mixpanel.track("Error during call", {
        name: sellerName,
      });
      endCall();
      console.error(error);
    }
  };

  useEffect(() => {
    if (calling && uid) {
      const interval = setInterval(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .update({
            timer: firebase.firestore.FieldValue.increment(1),
          });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [calling, uid]);

  useEffect(() => {
    let startTime;
    let interval;

    if (calling) {
      startTime = Date.now();
      mixpanel.time_event(`Call duration`);

      interval = setInterval(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .update({
            timer: firebase.firestore.FieldValue.increment(1),
          });
      }, 1000);
    }

    return () => {
      if (calling) {
        const durationSeconds = Math.floor((Date.now() - startTime) / 1000);
        mixpanel.track(`Call with duration`, {
          duration: durationSeconds,
          name: sellerName,
        });
        clearInterval(interval);
      }
    };
  }, [calling, uid]);

  useEffect(() => {
    if (!calling && currentChunk) {
      currentChunk.stop();
      setAudioChunks([]);
    }
  }, [calling]);

  useEffect(() => {
    if (listening && finalTranscript && aiSpeech) {
      stopListening();
    } else if (!listening && started && calling) {
      startListeningSpeech();
    }
  }, [aiSpeech]);

  // Used for react media recorder
  useEffect(() => {
    if (
      !aiSpeech &&
      !generating &&
      started &&
      (recorder.status === "idle" || recorder.status === "stopped")
    ) {
      recorder.startRecording();
    }

    if ((generating || aiSpeech) && recorder.status === "recording") {
      recorder.stopRecording();
    }
  }, [aiSpeech, generating]);

  useEffect(() => {
    if (calling) {
      if (!isMicrophoneAvailable) {
        setCalling(false);
        return alert("Turn on mic before calling");
      } else {
        backgroundRef.current.src =
          "https://upcdn.io/FW25bFR/raw/Apple%20iphone%20%206%20Original%20Ringtone%20Sound%20Effect.mp3";
        backgroundRef.current.play();
      }
    } else {
      reset();
    }
  }, [calling]);

  useEffect(() => {
    if (aiSpeech) {
      if (!started) {
        setStarted(true);
        startListeningSpeech();
        // startListening();
        setText(null);
        backgroundRef.current.pause();
        start();
      }
    }
  }, [aiSpeech]);

  useEffect(() => {
    if (!calling) {
      stopSpeechRecognitionAndChunk();
    } else {
      startSpeechRecognitionIfSupported();
    }
  }, [calling]);

  useEffect(() => {
    if (calling && uid) {
      firebase.firestore().collection("users").doc(uid).update({
        firstPhoneCall: true,
      });
    } else {
      reset();

      setGenerating(false);
    }
  }, [calling, uid]);

  useEffect(() => {
    if (
      !currentChunk &&
      audioChunks.length > 0 &&
      !interimTranscript &&
      !finalTranscript &&
      calling
    ) {
      playAudio(audioChunks.shift(), calling);
    }
  }, [currentChunk, audioChunks, interimTranscript, finalTranscript, calling]);

  // On load after conversationID created, send request to firebase to begin convo.
  useEffect(() => {
    if (conversationId) {
      setTimeout(() => {
        triggerTTS("[begin]");
      }, 100);
    }
  }, [conversationId]);

  // On load, create a doc with this conversation keyed by conversationId
  useEffect(() => {
    let conversationId = uuidv4();
    setConversationId(conversationId);

    firebase
      .firestore()
      .collection("Conversations")
      .doc(conversationId)
      .set({ conversation: "" });
  }, []);

  // After 8 seconds of silence, tell firebase we are being silent to prompt
  // a new response.
  useEffect(() => {
    let timeoutId;
    if (
      !currentChunk &&
      !finalTranscript &&
      !generating &&
      !interimTranscript &&
      !aiSpeech
    ) {
      timeoutId = setTimeout(() => {
        if (
          !currentChunk &&
          !finalTranscript &&
          !generating &&
          !interimTranscript &&
          !aiSpeech
        ) {
          triggerTTS("[silence]");
        }
      }, 8000);
    } else {
      clearTimeout(timeoutId);
    }
    return () => clearTimeout(timeoutId);
  }, [currentChunk, finalTranscript, aiSpeech, generating, interimTranscript]);

  useEffect(() => {
    if (interimTranscript) {
      setFinishedSpeaking(false);
    }
  }, [interimTranscript]);

  // Done speaking, prompt firebase now.
  useEffect(() => {
    if (finalTranscript && !aiSpeech) {
      console.log("Finished speaking");
      setTimeout(() => {
        setFinishedSpeaking(true);
      }, 1000);
      triggerTTS(finalTranscript);
    }
  }, [finalTranscript]);

  useEffect(() => {
    return () => {
      stopSpeechRecognitionAndChunk();
    };
  }, []);
  return (
    calling && (
      <>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.93)",
            position: "absolute",
            top: 0,
            overflow: "hidden",
            width: "100vw",
            height: "100vh",
            zIndex: 18000,
            opacity: 1,
            left: 0,
            overflow: "hidden",
          }}
          className="d-flex justify-content-center"
          onClick={() => {
            setCallStarted(true);
          }}
        >
          <MDBRow style={{ marginTop: "0%" }}>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  color: "white",
                  fontFamily: "PlusJSBold",
                  position: "absolute",
                  marginTop: "5%",
                }}
              >
                Note: these are not real people talking, it's all fake.
              </p>
            </MDBCol>
            <MDBCol
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="d-flex justify-content-center"
              size="6"
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <img
                    src={image}
                    style={{
                      height: 250,
                      width: 250,
                      borderRadius: 300,
                      marginTop: 80,
                      boxShadow: aiSpeech
                        ? `0px 0px ${shadowRadius}px 20px #38ef7d`
                        : null,
                      objectFit: "cover",
                      alignSelf: "center",
                    }}
                  />
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      color: "white",
                      fontFamily: "PlusJSMedium",
                      fontSize: 34,
                      marginTop: 55,
                      textAlign: "center",
                    }}
                  >
                    {sellerName}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="d-flex justify-content-center"
              size="6"
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <div
                    style={{
                      height: 250,
                      width: 250,
                      borderRadius: 300,
                      marginTop: 80,
                      boxShadow: !aiSpeech
                        ? `0px 0px ${shadowRadius}px 20px #38ef7d`
                        : null,
                      objectFit: "cover",
                      alignSelf: "center",
                    }}
                  >
                    <MDBRow>
                      <MDBCol
                        className="d-flex justify-content-center"
                        size="12"
                      >
                        <p
                          style={{
                            color: "white",
                            fontSize: 40,
                            marginTop: 88,
                            fontFamily: "PlusJSExtraBold",
                          }}
                        >
                          You
                        </p>
                      </MDBCol>
                      <MDBCol
                        className="d-flex justify-content-center"
                        size="12"
                      >
                        {!aiSpeech ? (
                          <MdRecordVoiceOver
                            style={{
                              color: "white",
                              fontSize: 40,
                              marginTop: 10,
                            }}
                          />
                        ) : (
                          <MdVoiceOverOff
                            style={{
                              color: "white",
                              fontSize: 40,
                              marginTop: 10,
                            }}
                          />
                        )}
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      color: "white",
                      fontFamily: "PlusJSMedium",
                      fontSize: 34,
                      marginTop: 55,
                      textAlign: "center",
                    }}
                  ></p>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol
              style={{ marginTop: -35 }}
              className="d-flex justify-content-center"
              size="12"
            >
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "white",
                }}
              >
                {renderText(text)}
              </p>
            </MDBCol>

            <MDBCol
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="d-flex justify-content-center "
              size="12"
            >
              <div
                style={{
                  height: 60,
                  width: 60,
                  backgroundColor: "red",
                  borderRadius: 200,
                  marginTop: -20,
                  marginLeft: 0,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="d-flex justify-content-center"
                onClick={async () => {
                  await endCall();
                }}
              >
                <ImPhoneHangUp
                  style={{
                    color: "white",
                    marginTop: 15,
                    fontSize: 25,
                  }}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </div>
        <div style={{ color: "white" }}>
          <div style={{ color: "white" }}>
            <audio
              volume={1}
              id="Jibril"
              loop
              ref={backgroundRef}
              src="https://res.cloudinary.com/dvgkc6gso/video/upload/v1676327827/backgroundSound_gyx0b4.mp3"
            />
          </div>
        </div>
      </>
    )
  );
};
export default Call;
