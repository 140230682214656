import React from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { IoCall } from "react-icons/io5";
import { withRouter } from "react-router-dom";
import { firebase } from "../../firebase/config";
import { FaLock } from "react-icons/fa";
import Scroll from "react-scroll";

function truncateString(str) {
  if (str.length > 12) {
    return str.slice(0, 12) + "...";
  } else {
    return str;
  }
}

const MiniRow = ({
  setCallingVisible,
  setCalling,
  setSellerName,
  setVoiceID,
  setImage,
  setLoginVisible,
  setPrompt,
  user,
  color,
  Category,
  avatars,
  marginTop,
  setUpgradeVisible,
  setVisible,
  subscribed,
}) => {
  return (
    <>
      <MDBRow
        style={{
          marginTop: -marginTop,
          overflowY: "hidden",
          backgroundColor: color === "Dark" ? "#111" : "#252525",
          paddingTop: 24,
          paddingBottom: 20,
        }}
        className="backy60"
      >
        <MDBCol className="d-flex justify-content-between" size="12">
          <p
            style={{
              fontFamily: "PlusJSExtraBold",
              fontSize: 17,
              fontWeight: "600",
              marginLeft: 28,
              color: "white",
            }}
          >
            {Category}
          </p>
        </MDBCol>

        <div
          style={{ paddingLeft: 20, marginTop: 0 }}
          class="horizontal-scroll"
        >
          {avatars.map((seller) => {
            return (
              <div
                key={seller.name}
                style={{ padding: 0, marginLeft: 0, marginRight: 10 }}
                class="mini-horizontal-scroll__item"
              >
                <MDBRow>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <img
                      src={seller.image}
                      style={{
                        width: 110,
                        height: 110,
                        objectFit: "cover",
                        borderRadius: 200,
                        border:
                          seller.Free === true || subscribed
                            ? "6px solid #77FFBA"
                            : "6px solid grey",
                        opacity: seller.Free === true || subscribed ? 1 : 0.5,
                      }}
                    />
                  </MDBCol>
                  <MDBCol
                    style={{
                      overflowY: "hidden",
                      zIndex: 0,
                      marginTop: -20,
                    }}
                    className="d-flex justify-content-center"
                    size="12"
                  >
                    <div
                      style={{
                        fontSize: 19,
                        fontFamily: "PlusJSBold",
                        textAlign: "center",
                        alignSelf: "center",
                        height: 40,
                        width: 40,
                        backgroundColor: "#4cda63",
                        backgroundColor:
                          seller.Free === true || subscribed
                            ? "#4cda63"
                            : "grey",
                        borderRadius: 200,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (seller.Free !== true && !subscribed) {
                          setUpgradeVisible(true);
                          setVisible(false);
                        } else {
                          if (!firebase.auth().currentUser) {
                            setLoginVisible(true);
                          } else {
                            setCallingVisible(true);
                            setCalling(true);
                            setSellerName(seller.name);
                            setVoiceID(seller.voiceID);
                            setPrompt(seller.prompt);
                            setImage(seller.image);
                            window.scrollTo({ top: 0 });
                          }
                        }
                      }}
                    >
                      {seller.Free === true || subscribed ? (
                        <IoCall style={{ marginTop: 12, fontSize: 16 }} />
                      ) : (
                        <FaLock style={{ marginTop: 12, fontSize: 16 }} />
                      )}
                    </div>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontSize: 15,
                        marginTop: 10,
                        fontFamily: "PlusJSBold",
                        textAlign: "center",
                        color: "white",
                        width: 130,
                        opacity: 0.6,
                      }}
                    >
                      {truncateString(
                        seller.name[0].toUpperCase() + seller.name.substring(1)
                      )}
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
            );
          })}
        </div>
      </MDBRow>
    </>
  );
};

export default withRouter(MiniRow);
