import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAg5YXOTLDzqxXwLRFSN99zvCYq7uusnEQ",
  authDomain: "sayso-dffe6.firebaseapp.com",
  projectId: "sayso-dffe6",
  storageBucket: "sayso-dffe6.appspot.com",
  messagingSenderId: "745969239780",
  appId: "1:745969239780:web:b4d470f4a74cf570128ef1",
  measurementId: "G-1BLZYKJ8W5",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  var functions = firebase.functions();
  var storage = firebase.storage();
}

export { firebase, functions, storage };
