import { useState } from "react";
import { storage } from "../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const useFirebaseStorage = () => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const uploadFile = async (file, filename) => {
        // const fileName = file.name;
        const fileRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on('state_changed', (snapshot) => {
            // Handle progress updates
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
        });

        try {
            await uploadTask;
            const url = await getDownloadURL(fileRef);
            console.log(`File uploaded successfully: ${url}`);
            return url;
        } catch (error) {
            // Handle unsuccessful uploads
            console.error(error);
            throw error;
        }
    };

    const uploadBlob = async (blob, fileName) => {
        setUploadProgress(0);
        const fileRef = ref(storage, fileName);
        const uploadTask = uploadBytesResumable(fileRef, blob);

        uploadTask.on('state_changed', (snapshot) => {
            // Handle progress updates
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
        });

        try {
            await uploadTask;
            const url = await getDownloadURL(fileRef);
            console.log(`Blob uploaded successfully: ${url}`);
            setUploadProgress(100)
            return url;
        } catch (error) {
            // Handle unsuccessful uploads
            console.error(error);
            setUploadProgress(0);
            throw error;
        }
    };

    return { uploadFile, uploadBlob, uploadProgress };
};


export default useFirebaseStorage;