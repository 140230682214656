import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import "../index.css";
import { SkyLightStateless } from "react-skylight";

const SignUpModal = ({
  isOpen,
  setIsOpen,
  setLoginVisible,
  signUp,
  setSignUp,
  Email,
  Password,
  setEmail,
  setPassword,
  onSubmitGoogle,
  onSubmitEmail,
  onSignInEmail,
  resetPassword,
  setResetPassword,
  sentPasswordReset,
  resetPasswordSubmit,
  error,
}) => {
  return (
    <SkyLightStateless
      hideOnOverlayClicked={true}
      onOverlayClicked={() => {
        setIsOpen(false);
        setLoginVisible(false);
      }}
      onCloseClicked={() => {
        setIsOpen(false);
        setLoginVisible(false);
      }}
      isVisible={isOpen}
      overlayStyles={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99,
        backgroundColor: "#181616",
        opacity: 0.8,
      }}
      dialogStyles={{
        zIndex: 9000,
        position: "fixed",
        width: "420px",
        minHeight: "60px",
        marginTop: "1%",
        top: "8%",
        height: signUp ? 380 : 500,
        backgroundColor: "transparent",
        border: "0px solid black",
        boxShadow: "none",
        left: 0,
        right: "15%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      closeButtonStyle={{
        color: "white",
        right: -180,
        position: "absolute",
        marginTop: 15,
        zIndex: 9000,
      }}
    >
      <div
        style={{
          paddingBottom: 30,
          width: 600,
          backgroundColor: "#101340",
          borderRadius: 9,
        }}
        className="backy60"
      >
        <MDBRow>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <p
                style={{
                  color: "#BEA2DA",
                  fontWeight: "900",
                  fontFamily: "TahomaBold",
                  fontSize: 33,
                  opacity: 1,
                  lineHeight: 1.2,
                  marginLeft: 0,
                }}
              >
                BollywoodAI
              </p>
            </div>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                color: "white",
                fontWeight: "900",
                fontFamily: "PlusJSBold",
                fontSize: 20,
                opacity: 1,
                lineHeight: 1.2,
                textAlign: "center",
                marginTop: 10,
                width: 400,
              }}
            >
              Continue with Google to start chatting
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                height: 40,
                width: 270,
                backgroundColor: "white",
                marginTop: 5,
                border: "1px solid #dadce0",
                borderRadius: 4,
                cursor: "pointer",
              }}
              className="d-flex justify-content-center"
              onClick={() => onSubmitGoogle()}
            >
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  color: "#3c4043",
                  fontSize: 14,
                  marginTop: 8,
                }}
                className="d-inline"
              >
                <img
                  className="d-inline"
                  style={{
                    position: "absolute",
                    marginLeft: -26,
                    fontSize: 13,
                    height: 20,
                    marginTop: 1,
                  }}
                  src={require("./Google.png")}
                />{" "}
                Continue with Google
              </p>
            </div>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                color: "white",
                fontSize: 14,
                marginTop: 15,
                width: "70%",
                textAlign: "center",
                opacity: 0.6,
              }}
            >
              Disclaimer: We're working on making BollywoodAI as reliable as
              possible but you may still experience issues. The accuracy and
              reliability of the translations may vary. We do not guarantee the
              availability or quality of the service. Please use this service
              responsibly.
            </p>
          </MDBCol>{" "}
        </MDBRow>
      </div>
    </SkyLightStateless>
  );
};

export default SignUpModal;
