import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { firebase } from "../../firebase/config";
import "../index.css";
import { NavLink, withRouter } from "react-router-dom";
import UpgradeDesktop from "./UpgradeDesktop";
import { AiOutlineMail } from "react-icons/ai";
import { RxPerson } from "react-icons/rx";
import Popup from "./Popup";

import SignUpModal from "./SignUpModal";
import { useUser } from "../../UserContext";
import mixpanel from "mixpanel-browser";

const MenuDesktop = ({
  upgradeVisible,
  setUpgradeVisible,
  loginVisible,
  setLoginVisible,
  discord,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavbar, setIsNavbar] = useState(true);
  const [signUp, setSignUp] = useState(true);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [email, setemail] = useState("");
  const [uid, setUID] = useState("");
  const [unread, setUnread] = useState(false);
  const [subscribed, setSubscribed] = useState("");
  const { user, setUser } = useUser();
  const [resetPassword, setResetPassword] = useState(true);
  const [sentPasswordReset, setSentPasswordReset] = useState(false);

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      setUID(uid);
      firebase
        .firestore()
        .collection("customers")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          let initActive = [];
          querySnapshot.forEach((doc) => {
            initActive.push(doc.data());
          });
          if (initActive.length > 0) {
            setSubscribed(true);
          }
        });

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          setemail(doc.data().email);
        });
    } else {
      setSubscribed(false);
    }
  }, [user]);

  const onSubmitEmail = (Email, Password) => {
    if (!Email || !Password) {
      return setError("Missing email or password!");
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(Email, Password)
        .then((userCredential) => {
          const uid = userCredential.user.uid;
          mixpanel.identify(uid);
          const searchParams = new URLSearchParams(window.location.search);
          const source = searchParams.get("source");
          if (source) {
            mixpanel.track("Signed Up", {
              Source: source,
              signUpMethod: "email",
            });
          } else {
            mixpanel.track("Signed Up", {
              Source: "referral",
              signUpMethod: "email",
            });
          }
          return firebase.firestore().collection("users").doc(uid).set({
            email: userCredential.user.email,
            uid: uid,
          });
        })
        .then((userData) => {
          setUser(userData);
          setIsOpen(false);
          setError(null);
        })

        .catch((error) => {
          if (error.message === "auth/popup-closed-by-user") {
            return null;
          } else {
            setError(error.message);
            throw error;
          }
        });
    }
  };

  const resetPasswordSubmit = (Email) => {
    if (Email) {
      firebase
        .auth()
        .sendPasswordResetEmail(Email)
        .then(() => {
          setSentPasswordReset(true);
        });
    }
  };

  const onSignInEmail = (Email, Password) => {
    if (!Email || !Password) {
      return setError("Missing email or password");
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(Email, Password)
        .then(() => {
          const uid = firebase.auth().currentUser.uid;
          mixpanel.identify(uid);

          setIsOpen(false);
          setError(null);
        })
        .catch((error) => {
          if (error.message === "auth/popup-closed-by-user") {
            return null;
          } else {
            setError(error.message);

            throw error;
          }
        });
    }
  };

  const onSubmitGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((socialAuthUser) => {
        const uid = socialAuthUser.user.uid;
        mixpanel.identify(uid);

        return firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              const searchParams = new URLSearchParams(window.location.search);
              const source = searchParams.get("source");
              if (source) {
                mixpanel.track("Signed Up", {
                  Source: source,
                  signUpMethod: "google",
                });
              } else {
                mixpanel.track("Signed Up", {
                  Source: "referral",
                  signUpMethod: "google",
                });
              }
              return firebase.firestore().collection("users").doc(uid).set({
                email: socialAuthUser.user.email,
                uid: uid,
              });
            } else {
              return doc.data();
            }
          });
      })
      .then((userData) => {
        setUser(userData); // Update the user state with the fetched or created user data
        setIsOpen(false);
        setError(null);
      })
      .catch((error) => {
        if (error.message === "auth/popup-closed-by-user") {
          return null;
        } else {
          setError(error.message);
          throw error;
        }
      });
  };

  useEffect(() => {
    if (isOpen) {
      setIsNavbar(false);
      setResetPassword(false);
      setError(null);
    } else {
      setIsNavbar(true);
      setResetPassword(false);
      setError(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (loginVisible) {
      setIsOpen(true);
      setError(null);
    } else {
      setIsOpen(false);
      setError(null);
    }
  }, [loginVisible]);

  return (
    <>
      <UpgradeDesktop
        visible={upgradeVisible}
        setVisible={setUpgradeVisible}
        setIsNavbar={setIsNavbar}
        discord={discord}
      />
      <SignUpModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setLoginVisible={setLoginVisible}
        resetPassword={resetPassword}
        signUp={signUp}
        setSignUp={setSignUp}
        sentPasswordReset={sentPasswordReset}
        setResetPassword={setResetPassword}
        resetPasswordSubmit={resetPasswordSubmit}
        Email={Email}
        Password={Password}
        setEmail={setEmail}
        setPassword={setPassword}
        onSubmitGoogle={onSubmitGoogle}
        onSubmitEmail={onSubmitEmail}
        onSignInEmail={onSignInEmail}
        error={error}
      />
      <MDBRow>
        <MDBCol
          className="d-flex justify-content-between backy80"
          style={{
            width: "100%",
            marginTop: "0%",
            position: "fixed",
            paddingBottom: 15,
            backgroundColor: "#101340",
            height: 80,
            zIndex: isNavbar ? 6000 : 1,
          }}
          size="12"
        >
          <NavLink
            to="/"
            exact
            style={{
              cursor: "pointer",
              position: "absolute",
              width: "14%",
              height: 75,
              left: 0,
            }}
            onClick={() => window.scrollTo(0, 0)}
          ></NavLink>
          <MDBRow style={{ marginTop: 21 }}>
            <MDBCol style={{ paddingTop: 4, paddingLeft: 30 }} size="4">
              <NavLink to="/" exact onClick={() => window.scrollTo(0, 0)}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#BEA2DA",
                      fontWeight: "900",
                      fontFamily: "TahomaBold",
                      fontSize: 33,
                      opacity: 1,
                      lineHeight: 1.2,
                      marginLeft: "10%",
                    }}
                  >
                    BollywoodAI
                  </p>
                </div>
              </NavLink>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ paddingRight: 20 }}>
            {/* <NavLink to="/discord" exact>
              <div
                className="d-flex justify-content-center"
                style={{
                  marginTop: 18,
                  width: 200,
                  marginRight: 30,
                  display: "inline-block",
                }}
                id="discord-button"
              >
                <a
                  style={{ width: 200, height: 42, borderRadius: 4 }}
                  href="https://discord.gg/MBqjVScAKe"
                  target="_blank"
                >
                  <div class="icon">
                    <svg
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 245 240"
                    >
                      <path
                        class="st0"
                        d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                      />
                      <path
                        class="st0"
                        d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                      />
                    </svg>
                  </div>
                  <span>Add Discord Bot</span>
                </a>
              </div>
            </NavLink> */}
            <div style={{ display: "inline-block" }} exact>
              {user ? (
                <>
                  {unread && (
                    <div
                      style={{
                        position: "absolute",
                        height: 9,
                        width: 9,
                        borderRadius: 200,
                        backgroundColor: "white",
                        marginTop: 27,
                        marginLeft: 16,
                      }}
                    ></div>
                  )}
                  <a target="_blank" href="mailto:help@bollywoodai.app">
                    <AiOutlineMail
                      style={{
                        color: "white",
                        marginTop: 27,
                        fontSize: 23,
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </>
              ) : (
                <div
                  style={{
                    border: "3px solid white",
                    marginTop: 19,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 17,
                    paddingRight: 17,
                    borderRadius: 3,
                    marginRight: -20,
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                  onClick={() => {
                    setSignUp(false);
                    setIsOpen(true);
                  }}
                >
                  <p
                    className="d-inline"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 15,
                      lineHeight: 1.2,
                    }}
                  >
                    LOGIN
                  </p>
                </div>
              )}
            </div>
            <div
              style={{
                display: "inline-block",
                marginLeft: 30,
                marginRight: 20,
              }}
            >
              {user ? (
                <RxPerson
                  onClick={() => {
                    console.log("clicked");
                    setPopUpVisible(true);
                  }}
                  style={{
                    color: "white",
                    marginTop: 27,
                    fontSize: 21,
                    cursor: "pointer",
                    zIndex: 90000,
                  }}
                />
              ) : (
                <div
                  lassName="d-flex justify-content-center"
                  style={{
                    border: "3px solid white",
                    marginTop: 19,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 3,
                    backgroundColor: "white",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                  onClick={() => {
                    setSignUp(true);
                    setIsOpen(true);
                  }}
                >
                  <p
                    className="d-inline"
                    style={{
                      color: "black",
                      cursor: "pointer",
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 15,
                      lineHeight: 1.2,
                    }}
                  >
                    JOIN FOR FREE
                  </p>
                </div>
              )}
            </div>
          </MDBRow>
        </MDBCol>
      </MDBRow>

      <Popup
        popUpVisible={popUpVisible}
        setPopUpVisible={setPopUpVisible}
        upgradeVisible={upgradeVisible}
        setUpgradeVisible={setUpgradeVisible}
        subscribed={subscribed}
        email={email}
        uid={uid}
      />
    </>
  );
};

export default withRouter(MenuDesktop);
