import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { firebase } from "../../firebase/config";
import MobileFooter from "../Layout/mobileFooter";
import { withRouter } from "react-router-dom";
import MobileTopMenu from "../Layout/mobileTopMenu";

const ContactMobile = ({ user }) => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#2b2b2b",
          color: "white",
        }}
      >
        <MDBRow>
          <MDBCol size="12">
            <MobileTopMenu user={user} />
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 150 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 20,
                fontWeight: "600",
                marginLeft: "5.5%",
              }}
            >
              Contact us
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              If you're experiencing any problems regarding your payment or
              issues with selling simply email help@bollywoodai.app. One of our
              team members will reach out shortly.
            </p>
          </MDBCol>
        </MDBRow>

        <MobileFooter />
      </div>
    </>
  );
};

export default withRouter(ContactMobile);
