import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import MobileFooter from "../Layout/mobileFooter";
import MobileTopMenu from "../Layout/mobileTopMenu";
import { withRouter } from "react-router-dom";

const FAQMobile = ({ user }) => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#101340",

          color: "white",
        }}
      >
        <MDBRow>
          <MDBCol size="12">
            <MobileTopMenu user={user} />
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 150 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSBold",
                fontSize: 25,
                fontWeight: "600",
                marginLeft: "5.5%",
              }}
            >
              FAQ
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 1,
              }}
            >
              1. How much does it cost for users?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              It's completely free.
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 1,
              }}
            >
              2. I’ve seen an avatar I want to talk to. How do I do it?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              It’s as simple as pressing the Whatsapp button. That will redirect
              you to a WhatsApp chat.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 1,
              }}
            >
              3. I want to suggest a new character how do I do it?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              Join our discord community and suggest characters in the
              characters channel.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginTop: 20 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 1,
              }}
            >
              4. How do I share an avatar?
            </p>
            <p
              style={{
                fontFamily: "PlusJSMedium",
                fontSize: 14,
                marginLeft: "5.5%",
                marginTop: -15,
                width: "90%",
                opacity: 0.7,
              }}
            >
              Share an avatar by sharing the contact with your friends in
              WhatsApp.
            </p>
          </MDBCol>
        </MDBRow>

        <MobileFooter />
      </div>
    </>
  );
};

export default withRouter(FAQMobile);
