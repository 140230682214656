import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { firebase } from "../../firebase/config";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import "../index.css";

const FullScreenMobile = ({ hideNav, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState("");
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState("");

  let myRef = React.createRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (user && firebase.auth().currentUser) {
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setEmail(doc.data().email);
          }
        });

      firebase
        .firestore()
        .collection("customers")
        .doc(user.uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          let initActive = [];
          querySnapshot.forEach((doc) => {
            initActive.push(doc.data());
          });
          if (initActive.length > 0) {
            setSubscribed(true);
          }
        });
    }
  }, [user]);

  return (
    <>
      <div
        style={{
          width: "100vh",
          position: "absolute",
          zIndex: 80000,
          backgroundColor: "#101340",

          height: "100vh",
        }}
      >
        <div ref={myRef} style={{ position: "absolute", top: 0 }}></div>
        <div
          style={{
            cursor: "pointer",
            fontSize: "15.6px",
            fontFamily: "PlusJSMedium",
            zIndex: 3,
            marginLeft: 0,
            top: 23,
            color: "black",
            zIndex: 2020,
            opacity: 0.8,
            paddingTop: 23,
            paddingLeft: 6,
            marginLeft: "1%",
          }}
        >
          <p
            className="d-inline"
            style={{
              color: "white",
              fontWeight: "900",
              marginTop: 45,
              fontFamily: "TahomaBold",
              fontSize: 20,
              opacity: 1,
              lineHeight: 1.2,
            }}
          >
            BollywoodAI
          </p>
        </div>

        <div
          className="d-flex justify-content-center "
          style={{
            cursor: "pointer",
            position: "fixed",
            fontSize: "15.6px",
            fontFamily: "PlusJSMedium",
            zIndex: 3,
            right: 18,
            top: 19,
            color: "black",
            zIndex: 6040,
            opacity: 0.8,
          }}
          onClick={() => {
            hideNav();
          }}
        >
          <IoClose style={{ fontSize: 23, opacity: 1, color: "white" }} />
        </div>
        <MDBRow style={{ borderTop: "0.1px solid #282828	", marginTop: 15 }}>
          <MDBCol
            style={{ borderBottom: "0.1px solid #282828", height: 49 }}
            size="12"
          >
            <NavLink
              to={firebase.auth().currentUser ? "/" : "/sign-in"}
              exact
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  color: "white",
                  fontSize: "16px",
                  fontFamily: "PlusJSMedium",
                  opacity: 0.4,
                }}
              >
                {email ? email : "Get chatting today"}
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol
            style={{ borderBottom: "0.1px solid #282828", height: 49 }}
            size="12"
          >
            <NavLink
              to="/dashboard"
              exact
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PlusJSMedium",
                  opacity: 0.7,
                }}
              >
                Personal
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ height: 49 }} size="12">
            {firebase.auth().currentUser ? (
              <NavLink
                to="/"
                exact
                onClick={() => {
                  hideNav();

                  window.scrollTo(0, 0);
                }}
              >
                <p
                  style={{
                    marginTop: 4,
                    marginLeft: "1%",
                    padding: "10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "PlusJSMedium",
                    opacity: 0.7,
                  }}
                >
                  Manage account
                </p>
              </NavLink>
            ) : (
              <NavLink
                to="/"
                exact
                onClick={() => {
                  hideNav();

                  window.scrollTo(0, 0);
                }}
              >
                <p
                  style={{
                    marginTop: 4,
                    marginLeft: "1%",
                    padding: "10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "PlusJSMedium",
                    opacity: 0.7,
                  }}
                >
                  Explore avatars
                </p>
              </NavLink>
            )}
          </MDBCol>
          <MDBCol style={{ height: 49 }} size="12">
            <NavLink
              to="/"
              exact
              onClick={() => {
                hideNav();

                window.scrollTo(0, 0);
              }}
            >
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PlusJSMedium",
                  opacity: 0.7,
                }}
              >
                Home
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol
            style={{ borderBottom: "0.1px solid #282828", height: 49 }}
            size="12"
          >
            <a target="_blank" href="mailto:help@bollywoodai.app">
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PlusJSMedium",
                  opacity: 0.7,
                }}
              >
                Help
              </p>
            </a>
          </MDBCol>
          <MDBCol style={{ height: 49 }} size="12">
            {subscribed || !firebase.auth().currentUser ? (
              <NavLink
                to="/privacy"
                exact
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <p
                  style={{
                    marginTop: 4,
                    marginLeft: "1%",
                    padding: "10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "PlusJSMedium",
                    opacity: 0.7,
                  }}
                >
                  Privacy Policy
                </p>
              </NavLink>
            ) : (
              <NavLink onClick={() => {}} to="/upgrade" exact>
                <p
                  style={{
                    marginTop: 4,
                    marginLeft: "1%",
                    padding: "10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "PlusJSMedium",
                    opacity: 0.7,
                  }}
                >
                  Upgrade
                </p>
              </NavLink>
            )}
          </MDBCol>
          <MDBCol style={{ height: 49 }} size="12">
            <NavLink
              to="/terms-of-use"
              exact
              onClick={() => {
                hideNav();

                window.scrollTo(0, 0);
              }}
            >
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PlusJSMedium",
                  opacity: 0.7,
                }}
              >
                Terms & Policy
              </p>
            </NavLink>
          </MDBCol>
          <MDBCol style={{ height: 49 }} size="12">
            {firebase.auth().currentUser ? (
              <p
                style={{
                  marginTop: 4,
                  marginLeft: "1%",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "PlusJSMedium",
                  opacity: 0.7,
                }}
                onClick={() => {
                  mixpanel.track(`Logged out`);
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      window.location.reload();
                    });
                }}
              >
                Log out
              </p>
            ) : (
              <NavLink
                to="/sign-in"
                exact
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <p
                  style={{
                    marginTop: 4,
                    marginLeft: "1%",
                    padding: "10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "PlusJSMedium",
                    opacity: 0.7,
                  }}
                >
                  Log in
                </p>
              </NavLink>
            )}
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default FullScreenMobile;
